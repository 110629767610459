/*
    Management login screen
*/
.nopadding {
  padding: 0 !important;
  margin: 0 !important; }

.inner-addon {
  position: relative; }

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 9px;
  pointer-events: none; }

/* align icon */
.left-addon .glyphicon {
  left: 0px; }

.right-addon .glyphicon {
  right: 0px; }

/* add padding  */
.left-addon input {
  padding-left: 30px; }

.right-addon input {
  padding-right: 30px; }

.well-login {
  background: #FFFFFF;
  border: 1px solid #979797;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px; }

.header-div {
  display: flex;
  justify-content: space-between;
  height: 14%;
}

.header-username{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27%;
  width: 50%;
  position: absolute;
  top: 17%;
  left: 23%;
  text-align: center;
  font-family: "Arial";
  font-weight: bold;
  color: black;
}

.header-points{
  height: 27%;
  width: 53%;
  position: absolute;
  top: 58%;
  left: 20%;
  text-align: center;
  font-family: "Arial";
  font-weight: bold;
  color: black;
}

.header-name-hide-points{
  height: 43%;
  width: 65%;
  position: absolute;
  top: 6%;
  left: 2%;
  text-align: center;
  font-family: "Arial";
  font-weight: bold;
  color: black;
}


.header-div-no-points {
  display: flex;
  justify-content: space-between;
  height: 10%;
}

.header-left-col {
  width: 38%;
  position: relative;
  height: 100%;
}

.header-center-col {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-right-col {
  width: 37%;
  display: flex;
  justify-content: flex-end;
}

.header-avatar-student-container {
  margin-top: 3%;
  margin-left: 3%;
  height: 100%;
  width: 72%;
}

.header-avatar {
  height: 70%;
  position: absolute;
  top: 17%;
  left: 7%;
  width: 12%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-y: center;
}

.header-student-container {
  margin-top: -2%;
  margin-left: -3%;
  height: 100%;
  width: 72%;
}

.header-current-avatar {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  position: absolute;
  z-index: 4;
}


.new-team-dialog-container {
  display: inline-block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  position: absolute;
  background-image: url(../assets/images/dialogs/dialog-welcomeTeam.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center
}

.header-team-name {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 1.95px;
  border-radius: 10px;
  background: #7CBA4A;
  border: 3px solid #3E6F2B;
  width: 100%;
  height: 53%;
  text-align: center;
  padding-top: 5%;
  margin-top: -6%;
  z-index: 1000;
}

.header-team-no-points{
  height: 75%;
}

.awarded-avatar-container {
  width: 32%;
  height: 40%;
  position: absolute;
  top: 28%;
  left: 33%;
  z-index: 1101;
  background-image: url(../assets/images/dialogs/dialog-newCharacter.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  -webkit-animation: animate-winning-team 4s linear;
  -moz-animation: animate-winning-team 4s linear;
  -o-animation: animate-winning-team 4s linear;
  animation: animate-winning-team 4s linear;
  animation-fill-mode: forwards;
}

.winning-team-avatar {
  width: 80%;
  height: 68%;
  position: absolute;
  top: 19%;
  left: 8%;
  z-index: 1101;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
}

@keyframes animate-winning-team {
  0% {
    transform: translate(0, 0) scale(0);
  }

  5% {
    transform: translate(0, 0) scale(1);
  }

  40% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }

  80% {
    transform: translate(-200%, -75%) scale(0.01);
    opacity: 0;
  }

  100% {
    transform: translate(-200%, -75%) scale(0.01);
    opacity: 0;
  }
}

.animate-header-team-name {
  -webkit-animation: animate-header 5s linear;
  -moz-animation: animate-header 5s linear;
  -o-animation: animate-header 5s linear;
  animation: animate-header 5s linear;
  animation-fill-mode: forwards;

}

.header-your-team {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: black;
  letter-spacing: 1.95px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  width: 62%;
  padding-top: 2%;
  margin-top: -2%;
  z-index: 999;
}

.header-logout-button {
  background: url(../assets/images/headerFooter/bttn_goBack_bu.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 13%;
  height: 48%;
  margin-right: 2%;
  margin-top: 2%;
  border: 0;
  outline: 0;
}

.header-logout-button:hover {
  background-image: url(../assets/images/headerFooter/bttn_goBack_bh.svg);
}

.header-logout-button.disabled {
  opacity: 0.4;
}

.logout-button, .logout-button:active, .logout-button:focus {
  position: relative;
  left: 12%;
  background-image: url(../assets/images/buttons/bttn_logout_bu.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  outline: none;
  height: 50%;
  width: 25%;
  top: 5%;
}

.logout-button:hover {
  background-image: url(../assets/images/buttons/bttn_logout_bh.svg);
}

.footer-div {
  position: absolute;
  bottom: 0;
  height: 9%;
  width: 100%;
  display: flex;
}

.logout-div {
  /*background-image: url(../assets/images/headerFooter/logo_background.svg);*/
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  height: 100%;
  width: 30%;
  margin-left: -2%;
  margin-top: 2%;
  display: inline-block;
}

.countdown-wrapper {
  width: 72%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.countdown-div {
  height: 100%;
  width: 35%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countdown-number {
  height: 47%;
  width: 12%;
  color: black;
  background: white;
  border-radius: 100px;
  font-weight: 900;
  letter-spacing: 2px;
  z-index: 1101;
  display: flex;
  justify-content: center;
  align-items: center;
}

.diagnosticproduct-header-management {
  background-image: linear-gradient(-180deg, #A3D7DB 2%, #50AEA7 87%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 70px;
  text-align: center;
  overflow: hidden;
  z-index: 2; }

.diagnosticproduct-plus-school-container {
  float: left;
  text-align: left;
  /*position: relative;*/
  display: inline-block;
}

.diagnosticproduct-management-content {
  position:absolute;
  top: 70px;
  left: 200px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
}

.diagnosticproduct-management-shaded-bar {
  background-color: #D3D3D3;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #ddd;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.diagnosticproduct-management-text-button-bar {
  border: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
}

.diagnosticproduct-management-text-bar {
  float: left;
  padding-left: 10px;
}

.diagnosticproduct-management-button-bar {
  float: right;
  padding-right: 10px;
}

.diagnosticproduct-management-table {
  border: 1px solid #ddd;
}

.diagnosticproduct-management-borderless-table tr td {
  border: none !important;
}

.diagnosticproduct-management-assessment-score-table-header {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #808080;
}

td .diagnosticproduct-management-assessment-score-footer {
  text-align: center;
  color: #808080;
}

tr td .diagnosticproduct-management-assessment-score-table-cell {
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd !important;
}

.padding-15 {
  padding: 15px;
}

.diagnosticproduct-management-table-header {
  background-color: #D3D3D3;
  color: black;
}

.diagnosticproduct-header-student-with-background {
  background-image: linear-gradient(180deg, #A3D7DB 2%, #50AEA7 87%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.diagnosticproduct-header-student {
  width: 100%;
  max-width: 100%;
  z-index: 2;
  opacity: 0.92;
  overflow: hidden;
}

.adjust-login-logo {
  position: absolute;
}

  .diagnosticproduct-header-student-container {
    height: 10%;
    margin-bottom: 1%;
  }

/* Task bar component in student site */
.diagnosticproduct-student-header-taskbar-fade-in {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  margin: auto;
  width: 90%;
  height: 8%;
  text-align: center;
  opacity: 0;
  vertical-align: middle;
  margin-top: 0.75%;
  overflow: hidden;
  -webkit-animation: fadeIn 3s ease 3s forwards;
  -moz-animation: fadeIn 3s ease 3s forwards;
  -o-animation: fadeIn 3s ease 3s forwards;
  animation: fadeIn 3s ease 3s forwards;
}

.diagnosticproduct-student-header-taskbar {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  margin: auto;
  width: 90%;
  height: 0;
  padding-bottom: 6%;
  text-align: center;
  opacity: 0.8;
  vertical-align: middle;
  margin-top: 0.75%;
}

.task_bar_title {
  font-weight: bold;
  padding-left: 30px;
  float: left;
  font-family: Helvetica;
  line-height: 250%;
  color: #FFFFFF;
  letter-spacing: 5px;
}

.task_bar_right_container {
  float: right;
  text-align: left;
}

.vertical_text_container {
  display:inline-block;
}

.clear-btn {
  background: none;
  color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.side-menu-toggle-btn {
  width: 4%;
  height: 7%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 20.5%;
  left: -0.5%;
  z-index: 2;
}

.side-menu-container {
  position: absolute;
  top: 20%;
  left: -3%;
  width: 30.4%;
  height: 52%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.current-tab {
  width: 81%;
  height: 87%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10%;
  margin-left: 4%;
  background-position: center;
  z-index: 1;
}

.hide-tab {
  display: none;
}

.achievement-unused{
  width: 22%;
  height: 20%;
  position: absolute;
  top: 2%;
  left: 22%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 1101;
}

.leaderboard-unused{
  width: 22%;
  height: 20%;
  position: absolute;
  top: 2%;
  left: 45%;
  background: url(../assets/images/sideMenu/sideMenuBttn_tab2.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 1101;
}


.avatar-unused{
  width: 22%;
  height: 20%;
  position: absolute;
  top: 2%;
  left: 68%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 1101;
}

.current-avatar-tab-image{
  width: 100%;
  height: 33%;
}

.tab-open-image{
  width: 20%;
  height: 7%;
  position: absolute;
  top: 9%;
  left: 68%;
  z-index: 1101;
}

.select-avatar-btn{
  width: 50%;
  height: 10%;
  background: url(../assets/images/sideMenu/bttn_select_bu.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 30%;
  left: 3%;
}

.select-avatar-btn:hover{
  background: url(../assets/images/sideMenu/bttn_select_bh.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.avatar-preview{
  width: 39%;
  height: 32%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 45%;
  top: 19%;
}

.avatar-carousel-container{
  width: 74%;
  height: 38%;
  margin-left: 3.5%;
  position: absolute;
  top: 54%;
}

.avatar-carousel{
  width: 86%;
  height: 100%;
  display: inline-block;
  position: absolute;
  padding-top: 1%;
}

.empty-carousel-item {
  width: 23%;
  height: 30%;
  background: url(../assets/images/sideMenu/avatarCarousel_empty.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 2%;
  margin: 1%;
  display: inline-block;
}

.selected-carousel-item {
  background: url(../assets/images/sideMenu/avatarCarousel_selected.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.avatar-carousel-item {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.back-avatar-btn{
  width: 7%;
  height: 100%;
  background: url(../assets/images/sideMenu/bttn_avatarScrollLeft_bu.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}

.back-avatar-btn-disabled{
  width: 7%;
  height: 100%;
  background: url(../assets/images/sideMenu/bttn_avatarScrollLeft_bu_disabled.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}

.next-avatar-btn{
  width: 7%;
  height: 100%;
  background: url(../assets/images/sideMenu/bttn_avatarScrollRight_bu.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  position: absolute;
  right: 0;
}

.next-avatar-btn-disabled{
  width: 7%;
  height: 100%;
  background: url(../assets/images/sideMenu/bttn_avatarScrollRight_bu_disabled.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  position: absolute;
  right: 0;
}

.weekly-time {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vw;
  font-weight: 900;
  color: white;
  position: absolute;
  top: 22.4%;
  left: 9%;
  display: block;
  width: 60%;
  text-align: center;
}

.current-unit-sidebar{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vw;
  font-weight: 900;
  color: #4A4A4A;
  position: absolute;
  top: 23.4%;
  left: 39%;
  display: block;
  width: 60%;
  text-align: center;
}

.daily-points {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3vw;
  font-weight: bold;
  letter-spacing: 3px;
  color: black;
  position: absolute;
  top: 40%;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
}

.weekly-points {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6vw;
  font-weight: 700;
  letter-spacing: 3px;
  color: #4A4A4A;
  position: absolute;
  top: 54.5%;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
}

.total-points {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6vw;
  font-weight: 700;
  letter-spacing: 3px;
  color: #4A4A4A;
  position: absolute;
  top: 65.5%;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
}

.level-progress-container {
  width: 72%;
  height: 6.5%;
  position: absolute;
  top: 82%;
  left: 16%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.level-goal {
  display: inline-block;
  height: 95%;
  width: 10%;
  margin: 1%;
}

.level-star {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.school-name {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1vw;
  font-weight: 800;
  color: #4A4A4A;
  position: absolute;
  top: 92%;
  left: 0;
  display: block;
  width: 100%;
  margin: 4%;
  text-align: center;
  letter-spacing: 2px;
}

.team-leaderboard-name {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  position: absolute;
  top: 21%;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
}

.team-leaderboard-points {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  letter-spacing: 3px;
  color: #4A4A4A;
  position: absolute;
  top: 34%;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
}

.team-standing-container {
  width: 74%;
  height: 40%;
  margin-left: 3.5%;
  position: absolute;
  top: 55%;
}

.team-standing {
  width: 100%;
  height: 22%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
}

.team-standing-name {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #4A4A4A;
  width: 42%;
  margin: 0;
  margin-left: 14%;
  text-align: start;
  letter-spacing: 2px;
  position: relative;
}

.team-standing-points {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #4A4A4A;
  width: 50%;
  margin: 0;
  letter-spacing: 3px;
  position: relative;
  text-align: center;
}

.first-team{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings1_bu.svg);
}

.second-team{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings2_bu.svg);
}

.third-team{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings3_bu.svg);
}

.fourth-team{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings4_bu.svg);
}

.first-team-current{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings1_bh.svg);
}

.second-team-current{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings2_bh.svg);
}

.third-team-current{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings3_bh.svg);
}

.fourth-team-current{
  background-image: url(../assets/images/sideMenu/leaderboard_teamStandings4_bh.svg);
}

.animate-standing-1{
  -webkit-animation: animate-standing-1 2s linear;
  -moz-animation: animate-standing-1 2s linear;
  -o-animation: animate-standing-1 2s linear;
  animation: animate-standing-1 2s linear;
  animation-fill-mode: forwards;
}

.animate-standing-2{
  -webkit-animation: animate-standing-2 2s linear;
  -moz-animation: animate-standing-2 2s linear;
  -o-animation: animate-standing-2 2s linear;
  animation: animate-standing-2 2s linear;
  animation-fill-mode: forwards;
}

.animate-standing-3{
  -webkit-animation: animate-standing-3 2s linear;
  -moz-animation: animate-standing-3 2s linear;
  -o-animation: animate-standing-3 2s linear;
  animation: animate-standing-3 2s linear;
  animation-fill-mode: forwards;
}

@keyframes animate-standing-1 {
  0% {
    transform: translate(0px, 100%);
  }

  15% {
    transform: translate(0px, 100%);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes animate-standing-2 {
  0% {
    transform: translate(0px, 200%);
  }

  25% {
    transform: translate(0px, 200%);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes animate-standing-3 {
  0% {
    transform: translate(0px, 300%);
  }

  15% {
    transform: translate(0px, 300%);
  }

  100% {
    transform: translate(0px, 0px);
  }
}



.task_bar_button {
  background: url(../assets/images/destination/bttnGoBack.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 3%;
  padding-top: 3%;
  max-width: 3%;
  max-height: 60%;
  margin-left: 1%;
  margin-right: 2%;
  margin-top: 2%;
  border: 0;
  float: right;
}

.back_button_animate{
  animation: backButtonPulse 3s infinite;
}

/* Pre diagnostic task buttons */
.pre-diagnostic-task-select-content-div{
  width: 46%;
  height: 80%;  /* in case css3 not supported */
  height: calc(99% - 10vw);  /* height of header and task bar and a little less to acocunt for margin */
  position: absolute;
  overflow: hidden;
  right: 0;
  padding: 2%;
}

.pre-task-btn-container-base{
  pointer-events: auto;
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: auto;
}

.task-button-disabled {
  opacity: 0.5;
}

.pre-task-wrapper {
  width: 87%;
  height: 20%;
  display: flex;
  margin: auto;
}

.pre-task-btn-task-type {
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3.93px;
  pointer-events: auto;
  display: block;
  margin-top: -5%;
  margin-bottom: 0;
}

.pre-task-btn-task-name {
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3.6px;
  pointer-events: auto;
  display: block;
  margin-bottom: 1%;
}

.pre-task-btn-icon {
  pointer-events: auto;
  height: 17%;
  display: block;
  margin: auto;
}

.pre-task-btn-task-score {
  font-family: Helvetica;
  font-weight: 800;
  color: rgb(0, 0, 0);
  pointer-events: auto;
  width: 17%;
  display: inline-block;
  position: absolute;
  left: 78%;
  top: 44%;
}

.pre-task-btn-task-score.secondary-theme {
  top:  66% ;
}

.left-task-score {
  left: 4% !important;
}

.diagnostic-task-btn-task-prev-score {
  font-family: Helvetica;
  font-weight: 800;
  color: rgb(0, 0, 0);
  pointer-events: auto;
  width: 17%;
  display: inline-block;
  position: absolute;
  left: 78%;
  top: 26%;
}

.diagnostic-task-btn-task-prev-score.secondary-theme {
  top: 30% ;
}

.diagnostic-task-btn-task-score {
  font-family: Helvetica;
  font-weight: 800;
  color: rgb(0, 0, 0);
  pointer-events: auto;
  width: 17%;
  display: inline-block;
  position: absolute;
  left: 78%;
  top: 63%;
}

.diagnostic-task-btn-task-score.secondary-theme {
  top: 66%;
}

/* Task clouds in student site */
.task-wrapper {
  width: 100%;
  height: 33%;
  display: flex;
}
.task-btn-container-base {
  pointer-events: auto;
  text-align: center;
  display: inline-block;
  width: 50%;
  height: 100%;
  margin: auto;
}

.task-btn-container-base.ng-hide {
  transition: 0.5s linear all;
  opacity:0;
}

.task-btn-container-appear {
  -webkit-animation: taskBtnAppear 1s ease-in-out;
  -moz-animation: taskBtnAppear 1s ease-in-out;
  -o-animation: taskBtnAppear 1s ease-in-out;
  animation: taskBtnAppear 1s ease-in-out;
  animation-fill-mode: forwards;
}

.task-btn-task-type {
  font-family: Helvetica;
  font-weight: bold;
  color: #184760;
  letter-spacing: 3.93px;
  pointer-events: auto;
  display: block;
}

.task-btn-task-name {
  font-family: Helvetica;
  font-weight: bold;
  color: #184760;
  letter-spacing: 3.6px;
  pointer-events: auto;
  display: block;
}

.task-btn-icon {
  pointer-events: auto;
  height: 15%;
  display: block;
  margin: auto;
}

.task-btn-task-points {
  font-family: Helvetica;
  font-weight: 800;
  color: rgb(0, 0, 0);
  pointer-events: auto;
  width: 23%;
  display: inline-block;
  position: absolute;
  top: 80%;
  left: 16%;
}

.task-btn-task-score {
  font-family: Helvetica;
  font-weight: 800;
  color: rgb(0, 0, 0);
  pointer-events: auto;
  width: 21%;
  height: 14%;
  display: inline-block;
  position: absolute;
  left: 63%;
  top: 80%;
}

.task-btn-passage-title {
  font-family: Helvetica;
  font-weight: bold;
  color: #184760;
  letter-spacing: 3.6px;
  pointer-events: auto;
  display: block;
  white-space: pre;
}

.task-button, .task-button:active, .task-button:focus, .task-button:hover {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  pointer-events: auto;
  position: relative;
}

/** SubTask cloud styles **/
.read-and-listen-content-div{
  width: 60%;
  height: 20%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtask-btn-container {
  pointer-events: auto;
  text-align: center;
  width: 52%;
  height: 100%;
  display: inline-block;
}

.subtask-btn-icon {
  pointer-events: auto;
  height: 15%;
}

.subtask-btn-task-score {
  font-family: Helvetica;
  font-weight: 800;
  color: rgb(0, 0, 0);
  pointer-events: auto;
  width: 21%;
  height: 14%;
  display: inline-block;
  position: absolute;
  left: 71%;
  top: 72%;
}

.passages-avatar {
  width: 13%;
  height: 20%;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 5%;
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.total-points-container {
  width: 100%;
  height: 90%;
  border: 3px solid;
  border-radius: 15px;
  position: relative;
  top: 5%;
}

.task-cloud-task-type {
  font-family: Helvetica;
  font-weight: bold;
  color: #184760;
  letter-spacing: 3.93px;
  pointer-events: auto;
  width: 100%;
}

.task-cloud-task-name {
  font-family: Helvetica;
  font-weight: bold;
  color: #184760;
  letter-spacing: 3.6px;
  pointer-events: auto;
  width: 100%;
}

.task-cloud-icon {
  pointer-events: auto;
  height: 15%;
}

.task-cloud-task-pretest-label {
  font-family: Helvetica;
  font-size:18px;
  font-size: 1.3vw;
  color: #4A4A4A;
  pointer-events: auto;
  position: absolute;
  width: 100%;
}

.task-cloud-task-pretest-percentage {
  font-family: Helvetica;
  font-size: 24px;
  font-size: 1.5vw;
  color: #4A4A4A;
  pointer-events: auto;
  position: absolute;
  width: 100%;
}

.task-cloud-task-posttest-label {
  font-family: Helvetica;
  font-size:18px;
  font-size: 1.3vw;
  color: #4A4A4A;
  pointer-events: auto;
  position: absolute;
  width: 100%;
}

.task-cloud-task-posttest-percentage {
  font-family: Helvetica;
  font-size: 24px;
  font-size: 1.5vw;
  color: #4A4A4A;
  pointer-events: auto;
  position: absolute;
  width: 100%;
}

.task-cloud-task-posttest-label-1{
  left: 35%;
  top: 50%;
}

.task-cloud-task-posttest-percentage-1{
  left: 35%;
  top: 65%;
}

.task-cloud-task-pretest-label-1{
  left: 15%;
  top: 50%;
}

.task-cloud-task-pretest-percentage-1{
  left: 15%;
  top: 65%;
}

.task-cloud-task-type-1 {
  padding-top: 0%;
  position: absolute;
  top: 17%;
}

.task-cloud-task-name-1 {
  margin-top: 4%;
  left: 0%;
}



.task-cloud-task-points-1 {
  right: 30%;
  top: 55%;
}

.task-cloud-task-score-1 {
  left: 30%;
  top: 55%;
}

.task-cloud-task-type-2 {
  padding-top: 0%;
  position: absolute;
  top: 16%;
}

.task-cloud-task-name-2 {
  margin-top: 4%;
  left: 0%;
}



.task-cloud-task-points-2 {
  right: 30%;
  top: 55%;
}

.task-cloud-task-score-2 {
  left: 30%;
  top: 55%;
}

.task-cloud-task-posttest-label-2{
  left: 35%;
  top: 50%;
}

.task-cloud-task-posttest-percentage-2{
  left: 35%;
  top: 65%;
}

.task-cloud-task-pretest-label-2{
  left: 15%;
  top: 50%;
}

.task-cloud-task-pretest-percentage-2{
  left: 15%;
  top: 65%;
}

.task-cloud-task-type-3 {
  padding-top: 2%;
}

.task-cloud-task-name-3 {
  top: 46%;
  left: -4%;
}

.task-cloud-task-points-3 {
  right: 28%;
  top: 60%;
}

.task-cloud-task-score-3 {
  left: 28%;
  top: 60%;
}

.task-cloud-task-posttest-label-3{
  left: 38%;
  top: 57%;
}

.task-cloud-task-posttest-percentage-3{
  left: 38%;
  top: 70%;
}

.task-cloud-task-pretest-label-3{
  left: 18%;
  top: 57%;
}

.task-cloud-task-pretest-percentage-3{
  left: 18%;
  top: 70%;
}

.task-cloud-task-type-4 {
  padding-top: 4%;
  position: relative;
  right: 4%;
}

.task-cloud-task-name-4 {
  position: relative;
  top: 42%;
  left: -3%;
}


.task-cloud-task-points-4 {
  right: 30%;
  top: 65%;
}

.task-cloud-task-score-4 {
  left: 20%;
  top: 65%;
}

.task-cloud-task-posttest-label-4{
  left: 32%;
  top: 57%;
}

.task-cloud-task-posttest-percentage-4{
  left: 32%;
  top: 70%;
}

.task-cloud-task-pretest-label-4{
  left: 14%;
  top: 57%;
}

.task-cloud-task-pretest-percentage-4{
  left: 14%;
  top: 70%;
}

.task-cloud-task-type-5 {
  padding-top: 0%;
}

.task-cloud-task-name-5 {
  top: 42%;
  left: 0%;
}


.task-cloud-task-points-5 {
  right: 30%;
  top: 55%;
}

.task-cloud-task-score-5 {
  left: 25%;
  top: 55%;
}

.task-cloud-task-posttest-label-5{
  left: 33%;
  top: 55%;
}

.task-cloud-task-posttest-percentage-5{
  left: 33%;
  top: 70%;
}

.task-cloud-task-pretest-label-5{
  left: 15%;
  top: 55%;
}

.task-cloud-task-pretest-percentage-5{
  left: 15%;
  top: 70%;
}

.task-cloud-task-type-6 {
  padding-top: 0%;
}

.task-cloud-task-name-6 {
  top: 42%;
  left: 0%;
}

.task-cloud-task-points-6 {
  right: 30%;
  top: 55%;
}

.task-cloud-task-score-6 {
  left: 25%;
  top: 55%;
}

.task-cloud-task-posttest-label-6{
  left: 33%;
  top: 55%;
}

.task-cloud-task-posttest-percentage-6{
  left: 33%;
  top: 70%;
}

.task-cloud-task-pretest-label-6{
  left: 15%;
  top: 55%;
}

.task-cloud-task-pretest-percentage-6{
  left: 15%;
  top: 70%;
}

.task-cloud-task-type-7 {
  padding-top: 0%;
}

.task-cloud-task-name-7 {
  top: 42%;
  left: 0%;
}

.task-cloud-task-points-7 {
  right: 30%;
  top: 55%;
}

.task-cloud-task-score-7 {
  left: 30%;
  top: 55%;
}

.task-cloud-task-posttest-label-7{
  left: 35%;
  top: 55%;
}

.task-cloud-task-posttest-percentage-7{
  left: 35%;
  top: 70%;
}

.task-cloud-task-pretest-label-7{
  left: 15%;
  top: 55%;
}

.task-cloud-task-pretest-percentage-7{
  left: 15%;
  top: 70%;
}

.task-cloud-task-type-8 {
  padding-top: 0%;
}

.task-cloud-task-name-8 {
  top: 42%;
  left: -4%;
}

.task-cloud-task-points-8 {
  right: 30%;
  top: 65%;
}

.task-cloud-task-score-8 {
  left: 30%;
  top: 65%;
}

.task-cloud-task-posttest-label-8{
  left: 35%;
  top: 55%;
}

.task-cloud-task-posttest-percentage-8{
  left: 35%;
  top: 68%;
}

.task-cloud-task-pretest-label-8{
  left: 15%;
  top: 55%;
}

.task-cloud-task-pretest-percentage-8{
  left: 15%;
  top: 68%;
}

/* Total Points Cloud styles */

.total-points-cloud-score-map {
  font-family: Helvetica;
  font-weight: bold;
  color: #184760;
  letter-spacing: 3.93px;
  pointer-events: auto;
  top: 25%;
  position: relative;
}

.total-points-cloud-label-map {
  font-family: Helvetica;
  color: #184760;
  letter-spacing: 3.6px;
  pointer-events: auto;
  top: 12%;
  position: relative;
}

.total-points-label-container{
  width: 100%;
  height: 100%;
}

.total-points-cloud-container-map {
  left: 7%;
  width: 27%;
  height: 13%;
  top: 11%;
  -webkit-animation: floatCloudDwnUp 5s infinite linear;
  -moz-animation: floatCloudDwnUp 5s infinite linear;
  -o-animation: floatCloudDwnUp 5s infinite linear;
  animation: floatCloudDwnUp 5s infinite linear;
  animation-fill-mode: forwards;
}

.total-points-container-task-bar {
  position: absolute;
  pointer-events: auto;
  text-align: center;
  display: flex;
  width: 15%;
  height: 8%;
  left: 43%;
}

/* Management styles */

.diagnosticproduct-header-text-management {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 48px;
  color: #4A4A4A;
  letter-spacing: 7.86px;
  display: inline-block;
  margin-top: 15px;
  margin-left: 30px;
}

/* SHARED */

.grade-level-text-management {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 12px;
  color: #4A4A4A;
  letter-spacing: 1.95px;
  margin-left: 8vw;
  margin-top: 10px;
  float: left;
  position: absolute;
}

.welcome-username-management {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 24px;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  top: 26px;
  margin-left: 8vw;
  margin-top: 0px;
  float: left;
  position: absolute;
}

.welcome-username-student {
  font-family: Helvetica;
  font-weight: bold;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  padding-top: 2.25%;
  text-align: center;}

.management-logout-button, .management-logout-button:active, .management-logout-button:focus, .management-logout-button:hover {
  border: 3px solid #4A4A4A;
  border-radius: 12px;
  background-color: transparent;
  outline: none;
  float: right;
  margin-top: 15px;
  margin-right: 50px;
}


.management-login {
  font-size: 36px;
  font-family: Helvetica;
  color: #000000; }

.checkbox {
  font-family: "Helvetica";
  font-size: 14px;
  color: #000000; }

.remember-me-checkbox {
  border: 1px solid #CCCCCC;
  border-radius: 2px; }

.footer {
  right: 0;
  padding-right: 2%;
  position: absolute;
  bottom: 0; }

/* Management home screen */
#wrapper {
  padding-left: 250px;
  transition: all 0.4s ease 0s; }

#sidebar-wrapper {
  left: 0px;
  width: 200px;
  top: 70px;
  background-image: linear-gradient(90deg, #A3D7DB 8%, #50AEA7 93%);
  position: fixed;
  height: 100%;
  overflow: auto;
  transition: all 0.4s ease 0s; }

.sidebar-nav {
  top: 0;
  list-style: none;
  margin: 0;
  padding: 0; }

.sidebar-nav > .sidebar-brand {
  height: 100%;
  font-size: 2rem;
  line-height: 60px;
  padding-left: 1vw;
  text-align: left; }

.sidebar-nav > .sidebar-brand a {
  color: #999999; }

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none; }

.sidebar-header {
  font-size: 2rem;
  line-height: 0px;
  padding-top: 6%;
  padding-left: 1.25vw;
  color: black;
  /*padding-top: 5%;*/
}

.sidebar-item {
  font-size: 1.75rem;
  padding-left: 29%;
  color: white;
  margin-top: 5%;
}

.sidebar-item:hover {
  cursor: pointer;
}

.sidebar-item-focus {
  background-color: white;
  color: black;
}

.sidebar-subitem {
  font-size: 1.2rem;
  margin-left: 20%;
}

.sidebar-separator {
  height: 10px;
  border-bottom: 1px solid black;
  width: 80%;
  margin-left: 14%;
  margin-bottom: 4%;
}

.sidebar-separator-category {
  height: 10px;
  border-bottom: 1px solid #37958E;
  width: 80%;
  margin-left: 14%;
  margin-bottom: 4%;
}

/* Enrollment screen */
.enroll-content {
  margin-top: 10%; }

.help-block {
  color: #d00; }

.diagnosticproduct-badge {
  background: rgb(161, 207, 221); color: black
}

.diagnosticproduct-number-of-licenses-block {
  width:60px;
  height:25px;
  background: rgb(161, 207, 221);
  text-align: center;
  margin: auto;
}

/* Student screen login */

.diagnosticproduct-header-text {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 48px;
  font-size: 2.75vw;
  color: #4A4A4A;
  letter-spacing: 7.86px;
  display: inline-block;
  margin-left: 30px;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important; }

.login-form-wrapper {
  margin: auto;
  position: relative;
  width: 44.5%;
  height: 37%;
  z-index: 1101;
  margin-top: 24%;
  background-color: #fdfefe;
  border-radius: 25px;
  border: 5px solid #4b90e3;
}

.welcome-wrapper,
.usage-element-message,
.error-wrapper,
.info-wrapper,
.download-element-message {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #FFF;
  border-radius: 10px;
  border: 4px solid #4C90E2;
  padding: 10px;
  margin: auto;
  position: relative;
  width: 44.5%;
  z-index: 1101;
  margin-top: 24%;
}

.download-element-message {
  text-align: center ;
}

.download-app-wrapper {
  display: flex ;
  justify-content: center ;
  margin-top: 10px ;
}

.download-app-btn {
  border: 1px solid #ABA9A9 ;
  border-radius: 10px ;
  padding-right: 15px ;
  padding-left: 8px ;
}

.download-app-btn a:hover {
  text-decoration: none ;
}

.custom-alert {
  width: 65%;
  height: 25%;
  position: absolute !important;
  left: 27%;
  top: 7%;
  padding: 4%;
  display: flex;
  align-items: center;
}

.login-text-label {
  position: absolute;
  margin-top: 0.5em;
  margin-left: 1em;
  font-size: 26px;
}

.caps-lock-warning {
  display: flex ;
  background-color: rgb(255 226 159);
  border-radius: 5px ;
  color: rgb(103, 76, 12) ;
  font-weight: bold ;
  gap: 5px ;
  margin-bottom: 10px ;
  margin-top: -40px;
  padding: 5px ;
  opacity: 80%;
  text-wrap: nowrap;
}

.caps-lock-icon {
  background-image: url(../assets/images/caps-lock.svg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 1em;
  width: 1em;
  opacity: 80%;
  position: relative;
  float: right;
  margin-right: 1em;
  bottom: 6em;
  color: rgb(223, 170, 47);
}

.demo-alert {
  width: 85%;
  height: auto;
  padding: 1% 12%;
  align-items: center;
  margin-left: auto ;
  margin-right: auto ;
  margin-top: 10px ;
  position: absolute;
  display: flex;
  text-align: center;
}

.spinning-loader {
  position: absolute;
  left: 45%;
  top: 70%;
  width: 7%;
  height: 8%;
  border-radius: 50%;
  border: 5px solid rgba(29, 161, 242, 0.2);
  border-left-color: rgb(29, 161, 242);
  background: transparent;
  animation: rotate-s-loader 1s linear infinite;
  margin: 6rem auto;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.demo-select-form {
  background-color: #FFFFFF ;
  border: 6px solid #4A90E2 ;
  border-radius: 25px ;
  box-shadow: inset 0 0 2px ;
  margin: auto;
  position: relative;
  width: 54%;
  height: auto ;
  z-index: 1101;
  margin-top: 14%;
}

.demo-form-wrapper {
  height: 100%;
  width: 100%;
}

.demo-form-group {
  margin: auto;
  padding: 3%;
  width: 68%;
  height: 51%;
}

.demo-form-group.disabled {
  background-color: #dfdfdf;
}

.demo-dropdown {
  width: 78%;
  height: 15%;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 0 1pt black;
  padding-left: 10px;
  padding-bottom: 5px ;
  padding-top: 5px ;
  margin-left: 13%;
}

.demo-dropdown:disabled {
  background-color: #dfdfdf;
}

.demo-bird {
  width: 11%;
  height: 14%;
  position: absolute;
  top: 13%;
  left: 75%;
  background-image: url("/assets/images/loginDemoBird.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.demo-header-wrapper {
  padding-top: 15.3% ;
  margin-bottom: 5%;
  margin-left: 100px ;
  margin-right: 100px;
}

.demo-header {
  border-radius: 12px ;
  border-style: solid ;
  border-color: #F1BC43 ;
  border-width: 4px ;
  background-color: #FFDC6D ;
  font-family: Helvetica;
  font-weight: bold;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  text-align: center;
  margin-left: auto ;
  margin-right: auto ;
  width: 100% ;
}

.demo-field-text {
  font-family: Helvetica;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 0.18em;
  position: relative;
}

.login-field {
  font-family: Helvetica;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  border-radius: 10px;
  position: relative;
  outline: none;
  height: 38%;
  width: 100%;
  margin-bottom: 11%;
  margin-left: 1%;
  padding: 4%;
}

.login-field-text {
  font-family: Helvetica;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 0.18em;
  position: relative;
  margin-bottom: 15%;
  height: 38%;
}

.demo-submit-btn {
  margin-top: 40px ;
  margin-right: 35px ;
  text-align: right ;
}

.demo-submit-btn button {
  background-image: url(../assets/images/buttons/bttn_start_bu.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  background-color: transparent ;
  border: none ;
  height: 50px ;
  width: 110px ;
}

.demo-submit-btn button:hover {
  background-image: url(../assets/images/buttons/bttn_start_bh.svg);
  background-size: contain;
  background-repeat: no-repeat;
 }

 .demo-submit-btn button:disabled {
  background-image: url(../assets/images/buttons/bttn_start_bg.svg);
  background-size: contain;
  background-repeat: no-repeat;
 }

.btn-login {
  width: 25%;
  height: 21%;
  display: block;
  background-image: url(../assets/images/buttons/bttn_login_bu.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
  left: 74%;
  margin-top: 2%;
 }

 .btn-login:hover {
  background-image: url(../assets/images/buttons/bttn_login_bh.svg);
  background-size: contain;
  background-repeat: no-repeat;
 }

 .btn-login:disabled {
  background-image: url(../assets/images/buttons/bttn_login_bg.svg);
  background-size: contain;
  background-repeat: no-repeat;
 }

 .login-clever {
  display: flex ;
  justify-content: space-around ;
  align-items: center ;
  max-width: 100%;
  height: auto;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  position: relative;
  margin-top: 10%;
  text-align: center;
 }

 .login-form {
  height: 94%;
  width: 100%;
  padding: 21% 13% 0;

 }

.student-form-group {
  display: inline-block;
  width: 44%;
  height: 100%;
  margin-bottom: 15px;
}

.student-form-group-inputs {
  display: inline-block;
  width: 54%;
  height: 100%;
  margin-bottom: 15px;
}

.student-form-group-wrapper {
  display: flex;
  width: 100%;
  height: 61%;
}

.form-group {
  padding-bottom: 30px; }

.overlay-fil-logo{
  margin: 1%;
  height: 20%;
  position: absolute;
  z-index: 1;
}

.background-image-overlay {
  background-image: linear-gradient(180deg, rgb(255, 255, 255, 0.9) 0%, rgb(255, 255, 255, 0) 90%);
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.background-image {
  background-image: url(../assets/images/dialogs/wordflightLoginBackground.svg);
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.base-background-image {
  background-size: auto auto;
  background-position: auto;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

  /* Needed to disable extra padding added by iPad user agent stylesheet */
button {
  color: #000000;
  padding: 0;
}

button:disabled {
  color: rgba(16, 16, 16, 0.3);
}

.theme-1-pre-diagnostic-zoom {
  animation: theme-1-pre-diagnostic-zoom 2s linear;
  -webkit-animation: theme-1-pre-diagnostic-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-1-mid-diagnostic-zoom {
  animation: theme-1-mid-diagnostic-zoom 2s linear;
  -webkit-animation: theme-1-mid-diagnostic-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-1-post-diagnostic-zoom {
    animation: theme-1-post-diagnostic-zoom 2s linear;
    -webkit-animation: theme-1-post-diagnostic-zoom 2s linear;
    animation-fill-mode: forwards;
}

.theme-1-obj1-zoom {
    animation: theme-1-obj1-zoom 2s linear;
    -webkit-animation: theme-1-obj1-zoom 2s linear;
    animation-fill-mode: forwards;
}

.theme-1-obj2-zoom {
    animation: theme-1-obj2-zoom 2s linear;
    -webkit-animation: theme-1-obj2-zoom 2s linear;
    animation-fill-mode: forwards;
}

.theme-1-obj3-zoom {
    animation: theme-1-obj3-zoom 2s linear;
    -webkit-animation: theme-1-obj3-zoom 2s linear;
    animation-fill-mode: forwards;
}

.theme-1-obj4-zoom {
    animation: theme-1-obj4-zoom 2s linear;
    -webkit-animation: theme-1-obj4-zoom 2s linear;
    animation-fill-mode: forwards;
}

.theme-1-obj5-zoom {
    animation: theme-1-obj5-zoom 2s linear;
    -webkit-animation: theme-1-obj5-zoom 2s linear;
    animation-fill-mode: forwards;
}

.theme-1-obj6-zoom {
    animation: theme-1-obj6-zoom 2s linear;
    -webkit-animation: theme-1-obj6-zoom 2s linear;
    animation-fill-mode: forwards;
}

.theme-2-pre-diagnostic-zoom {
  animation: theme-2-pre-diagnostic-zoom 2s linear;
  -webkit-animation: theme-2-pre-diagnostic-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-mid-diagnostic-zoom {
  animation: theme-2-mid-diagnostic-zoom 2s linear;
  -webkit-animation: theme-2-mid-diagnostic-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-post-diagnostic-zoom {
  animation: theme-2-post-diagnostic-zoom 2s linear;
  -webkit-animation: theme-2-post-diagnostic-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-obj1-zoom {
  animation: theme-2-obj1-zoom 2s linear;
  -webkit-animation: theme-2-obj1-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-obj2-zoom {
  animation: theme-2-obj2-zoom 2s linear;
  -webkit-animation: theme-2-obj2-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-obj3-zoom {
  animation: theme-2-obj3-zoom 2s linear;
  -webkit-animation: theme-2-obj3-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-obj4-zoom {
  animation: theme-2-obj4-zoom 2s linear;
  -webkit-animation: theme-2-obj4-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-obj5-zoom {
  animation: theme-2-obj5-zoom 2s linear;
  -webkit-animation: theme-2-obj5-zoom 2s linear;
  animation-fill-mode: forwards;
}

.theme-2-obj6-zoom {
  animation: theme-2-obj6-zoom 2s linear;
  -webkit-animation: theme-2-obj6-zoom 2s linear;
  animation-fill-mode: forwards;
}

@media (max-height: 647px){
  html, body {
    width: 100%;
    height: 100%;
    min-height: 600px;
    min-width: 845px;
    max-width: 845px;
    margin: 0 auto;
    padding: 0;
    word-wrap: normal;
  }

  .font-11{font-size: 8px;}
  .font-13{font-size: 8px;}
  .font-14{font-size: 11px;}
  .font-15{font-size: 11px;}
  .font-17{font-size: 13px;}
  .font-18{font-size: 14px;}
  .font-20{font-size: 15px;}
  .font-24{font-size: 16px;}
  .font-25{font-size: 18px;}
  .font-26{font-size: 19px;}
  .font-27{font-size: 20px;}
  .font-30{font-size: 21px;}
  .font-42{font-size: 30px;}
  .font-50{font-size: 36px;}
  .font-80{font-size: 50px;}
  .font-task-bar-name{font-size: 9px;}
  .font-passage-title{font-size: 12px;}
  .task_bar_task_icon_height{height: 20px;}

  .root-word-top-image-placeholder,
  .root-word-bottom-image-placeholder {
    height: 70% ;
  }

  @keyframes floatIncorrectUp {
    0% {
      color: grey;
      transform: translate(0px, 0px) scale(1);
    }

    100% {
      color: grey;
      transform: translate(-1%, -150%) scale(0.80);
      /* opacity: 0; */
    }
  }

  @keyframes floatIncorrectTopUp {
    0% {
      color: grey;
      transform: translate(0px, 0px);
    }

    100% {
      color: grey;
      transform: translate(0px, -98%);
      /* opacity: 0; */
    }
  }

  @keyframes taskBarNameAnimation {
    0% { transform: translate(-10vh, 100vh); font-size: 5vh; padding-bottom: 4vh;}
   20% { transform: translate(-10vh, 100vh); font-size: 5vh; padding-bottom: 4vh;}
   40% { transform: translate(-10vh, 35vh); font-size: 5vh; padding-bottom: 4vh;}
   80% { transform: translate(-10vh, 35vh); font-size: 5vh; padding-bottom: 4vh;}
  100% { transform: translate(0px, 0px); font-size: 9px; }
  }


  @keyframes taskBarSubTextAnimation {
    0% { transform: translate(-10vh, 100vh); font-size: 3vh; }
   40% { transform: translate(-10vh, 100vh); font-size: 3vh; }
   60% { transform: translate(-10vh, 35vh); font-size: 3vh; }
   80% { transform: translate(-10vh, 35vh); font-size: 3vh; }
  100% { transform: translate(0px, 0px); font-size: 7px; }
  }

  @keyframes animate-header {
    0% {
      transform: translate(0%, 1050%) scale(2);
    }

    60% {
      transform: translate(0%, 1050%) scale(2);
    }

    100% {
      transform: translate(0%, 0) scale(1);
    }
  }

  @keyframes theme-1-pre-diagnostic-zoom {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    80% {
        opacity: 1;
        transform: scale(3.0) translate(6em,-7em);
        -webkit-transform: scale(3.0) translate(6em,-7em);
    }
    100% {
        opacity: 0;
        transform: scale(3.0) translate(6em,-7em);
        -webkit-transform: scale(3.0) translate(6em,-7em);
    }
  }

  @keyframes theme-1-mid-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(4.0) translate(-6em,2em);
          -webkit-transform: scale(4.0) translate(-6em,2em);
      }
      100% {
          opacity: 0;
          transform: scale(4.0) translate(-6em,2em);
          -webkit-transform: scale(4.0) translate(-6em,2em);
      }
  }



  @keyframes theme-1-post-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(2.1) translate(-7em,10em);
          -webkit-transform: scale(2.1) translate(-7em,10em);
      }
      100% {
          opacity: 0;
          transform: scale(2.1) translate(-7em,10em);
          -webkit-transform: scale(2.1) translate(-7em,10em);
      }
  }

  @keyframes theme-1-obj1-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(-14em,-11em);
          -webkit-transform: scale(3.0) translate(-14em,-11em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(-14em,-11em);
          -webkit-transform: scale(3.0) translate(-14em,-11em);
      }
  }

  @keyframes theme-1-obj2-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(4.0) translate(-17em,-4em);
          -webkit-transform: scale(4.0) translate(-17em,-4em);
      }
      100% {
          opacity: 0;
          transform: scale(4.0) translate(-17em,-4em);
          -webkit-transform: scale(4.0) translate(-17em,-4em);
      }
  }

  @keyframes theme-1-obj3-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.3) translate(-21em,4em);
          -webkit-transform: scale(3.3) translate(-21em,4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.3) translate(-21em,4em);
          -webkit-transform: scale(3.3) translate(-21em,4em);
      }
  }


  @keyframes theme-1-obj4-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(10em,6em);
          -webkit-transform: scale(3.0) translate(10em,6em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(10em,6em);
          -webkit-transform: scale(3.0) translate(10em,6em);
      }
  }


  @keyframes theme-1-obj5-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(20em,-3em);
          -webkit-transform: scale(3.0) translate(20em,-3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(20em,-3em);
          -webkit-transform: scale(3.0) translate(20em,-3em);
      }
  }

  @keyframes theme-1-obj6-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(20em,14em);
          -webkit-transform: scale(3.0) translate(20em,14em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(20em,14em);
          -webkit-transform: scale(3.0) translate(20em,14em);
      }
  }

  @keyframes theme-2-pre-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
  }

  @keyframes theme-2-mid-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(20em,12em);
      -webkit-transform: scale(4.0) translate(20em,12em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(20em,12em);
      -webkit-transform: scale(4.0) translate(20em,12em);
    }
  }



  @keyframes theme-2-post-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(2.1) translate(-15em,14em);
      -webkit-transform: scale(2.1) translate(-15em,14em);
    }
    100% {
      opacity: 0;
      transform: scale(2.1) translate(-15em,14em);
      -webkit-transform: scale(2.1) translate(-15em,14em);
    }
  }

  @keyframes theme-2-obj1-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
  }

  @keyframes theme-2-obj2-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
  }

  @keyframes theme-2-obj3-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.3) translate(16em,4em);
      -webkit-transform: scale(3.3) translate(16em,4em);
    }
    100% {
      opacity: 0;
      transform: scale(3.3) translate(16em,4em);
      -webkit-transform: scale(3.3) translate(16em,4em);
    }
  }


  @keyframes theme-2-obj4-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(8em,10em);
      -webkit-transform: scale(3.0) translate(8em,10em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(8em,10em);
      -webkit-transform: scale(3.0) translate(8em,10em);
    }
  }


  @keyframes theme-2-obj5-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-3em,6em);
      -webkit-transform: scale(3.0) translate(-3em,6em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-3em,6em);
      -webkit-transform: scale(3.0) translate(-3em,6em);
    }
  }

  @keyframes theme-2-obj6-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-15em,5em);
      -webkit-transform: scale(3.0) translate(-15em,5em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-15em,5em);
      -webkit-transform: scale(3.0) translate(-15em,5em);
    }
  }
}

@media (min-height: 648px){
  html, body {
    width: 100%;
    height: 100%;
    min-width: 845px;
    max-width: 913px;
    margin: 0 auto;
    padding: 0;
    word-wrap: normal;
  }

  .font-11{font-size: 8px;}
  .font-13{font-size: 8px;}
  .font-14{font-size: 11px;}
  .font-15{font-size: 11px;}
  .font-17{font-size: 14px;}
  .font-18{font-size: 15px;}
  .font-20{font-size: 16px;}
  .font-24{font-size: 18px;}
  .font-25{font-size: 19px;}
  .font-26{font-size: 20px;}
  .font-27{font-size: 21px;}
  .font-30{font-size: 25px;}
  .font-42{font-size: 30px;}
  .font-50{font-size: 42px;}
  .font-80{font-size: 60px;}
  .font-passage-title{font-size: 18px;}
  .font-task-bar-name{font-size: 11px;}
  .task_bar_task_icon_height{height: 30px;}


  .root-word-top-image-placeholder,
  .root-word-bottom-image-placeholder {
    height: 70% ;
  }

  @keyframes floatIncorrectUp {
    0% {
      color: grey;
      transform: translate(0px, 0px) scale(1);
    }

    100% {
      color: grey;
      transform: translate(-1%, -180%) scale(0.80);
      /* opacity: 0; */
    }
  }

  @keyframes floatIncorrectTopUp {
    0% {
      color: grey;
      transform: translate(0px, 0px);
    }

    100% {
      color: grey;
      transform: translate(0px, -115%);
      /* opacity: 0; */
    }
  }

  @keyframes taskBarNameAnimation {
    0% { transform: translate(-10vh, 100vh); font-size: 5vh; padding-bottom: 4vh;}
   20% { transform: translate(-10vh, 100vh); font-size: 5vh; padding-bottom: 4vh;}
   40% { transform: translate(-10vh, 35vh); font-size: 5vh; padding-bottom: 4vh;}
   80% { transform: translate(-10vh, 35vh); font-size: 5vh; padding-bottom: 4vh;}
  100% { transform: translate(0px, 0px); font-size: 15px; }
  }

  @keyframes taskBarLongNameAnimation {
    0% { transform: translate(-10vh, 100vh); font-size: 3.75vh; padding-bottom: 4vh;}
    20% { transform: translate(-10vh, 100vh); font-size: 3.75vh; padding-bottom: 4vh;}
    40% { transform: translate(-10vh, 35vh); font-size: 3.75vh; padding-bottom: 4vh;}
    80% { transform: translate(-10vh, 35vh); font-size: 3.75vh; padding-bottom: 4vh;}
    100% { transform: translate(0px, 0px); font-size: 15px; }
  }

  @keyframes taskBarSubTextAnimation {
    0% { transform: translate(-10vh, 100vh); font-size:3vh; }
   40% { transform: translate(-10vh, 100vh); font-size: 3vh; }
   60% { transform: translate(-10vh, 35vh); font-size: 3vh; }
   80% { transform: translate(-10vh, 35vh); font-size: 3vh; }
  100% { transform: translate(0px, 0px); font-size: 13px; }
  }

  @keyframes animate-header {
    0% {
      transform: translate(0%, 925%) scale(1.8);
    }

    60% {
      transform: translate(0%, 925%) scale(1.8);
    }

    100% {
      transform: translate(0%, 0) scale(1);
    }
  }

  @keyframes theme-1-pre-diagnostic-zoom {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    80% {
        opacity: 1;
        transform: scale(3.0) translate(6em,-7em);
        -webkit-transform: scale(3.0) translate(6em,-7em);
    }
    100% {
        opacity: 0;
        transform: scale(3.0) translate(6em,-7em);
        -webkit-transform: scale(3.0) translate(6em,-7em);
    }
  }

  @keyframes theme-1-mid-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(4.0) translate(-7em,2em);
          -webkit-transform: scale(4.0) translate(-7em,2em);
      }
      100% {
          opacity: 0;
          transform: scale(4.0) translate(-7em,2em);
          -webkit-transform: scale(4.0) translate(-7em,2em);
      }
  }

  @keyframes theme-1-post-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(2.1) translate(-7em,12em);
          -webkit-transform: scale(2.1) translate(-7em,12em);
      }
      100% {
          opacity: 0;
          transform: scale(2.1) translate(-7em,12em);
          -webkit-transform: scale(2.1) translate(-7em,12em);
      }
  }

  @keyframes theme-1-obj1-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(-16em,-13em);
          -webkit-transform: scale(3.0) translate(-16em,-13em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(-16em,-13em);
          -webkit-transform: scale(3.0) translate(-16em,-13em);
      }
  }

  @keyframes theme-1-obj2-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(4.0) translate(-19em,-4em);
          -webkit-transform: scale(4.0) translate(-19em,-4em);
      }
      100% {
          opacity: 0;
          transform: scale(4.0) translate(-19em,-4em);
          -webkit-transform: scale(4.0) translate(-19em,-4em);
      }
  }

  @keyframes theme-1-obj3-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.3) translate(-22em,4em);
          -webkit-transform: scale(3.3) translate(-22em,4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.3) translate(-22em,4em);
          -webkit-transform: scale(3.3) translate(-22em,4em);
      }
  }

  @keyframes theme-1-obj4-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(10em,6em);
          -webkit-transform: scale(3.0) translate(10em,6em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(10em,6em);
          -webkit-transform: scale(3.0) translate(10em,6em);
      }
  }

  @keyframes theme-1-obj5-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(21em,-3em);
          -webkit-transform: scale(3.0) translate(21em,-3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(21em,-3em);
          -webkit-transform: scale(3.0) translate(21em,-3em);
      }
  }

  @keyframes theme-1-obj6-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(21em,15em);
          -webkit-transform: scale(3.0) translate(21em,15em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(21em,15em);
          -webkit-transform: scale(3.0) translate(21em,15em);
      }
  }

  @keyframes theme-2-pre-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
  }

  @keyframes theme-2-mid-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(20em,12em);
      -webkit-transform: scale(4.0) translate(20em,12em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(20em,12em);
      -webkit-transform: scale(4.0) translate(20em,12em);
    }
  }

  @keyframes theme-2-post-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(2.1) translate(-18em,14em);
      -webkit-transform: scale(2.1) translate(-18em,14em);
    }
    100% {
      opacity: 0;
      transform: scale(2.1) translate(-18em,14em);
      -webkit-transform: scale(2.1) translate(-18em,14em);
    }
  }

  @keyframes theme-2-obj1-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-16em,-13em);
      -webkit-transform: scale(3.0) translate(-16em,-13em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-16em,-13em);
      -webkit-transform: scale(3.0) translate(-16em,-13em);
    }
  }

  @keyframes theme-2-obj2-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
  }

  @keyframes theme-2-obj3-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.3) translate(18em,4em);
      -webkit-transform: scale(3.3) translate(18em,4em);
    }
    100% {
      opacity: 0;
      transform: scale(3.3) translate(18em,4em);
      -webkit-transform: scale(3.3) translate(18em,4em);
    }
  }

  @keyframes theme-2-obj4-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(14em,12em);
      -webkit-transform: scale(3.0) translate(14em,12em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(14em,12em);
      -webkit-transform: scale(3.0) translate(14em,12em);
    }
  }

  @keyframes theme-2-obj5-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-3em,8em);
      -webkit-transform: scale(3.0) translate(-3em,8em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-3em,8em);
      -webkit-transform: scale(3.0) translate(-3em,8em);
    }
  }

  @keyframes theme-2-obj6-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-15em,5em);
      -webkit-transform: scale(3.0) translate(-15em,5em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-15em,5em);
      -webkit-transform: scale(3.0) translate(-15em,5em);
    }
  }
}

@media (min-height: 728px){
  html, body {
    width: 100%;
    height: 100%;
    min-width: 845px;
    max-width: 1026px;
    margin: 0 auto;
    padding: 0;
    word-wrap: normal;
  }

  .font-11{font-size: 11px;}
  .font-13{font-size: 11px;}
  .font-14{font-size: 14px;}
  .font-15{font-size: 15px;}
  .font-17{font-size: 15px;}
  .font-18{font-size: 15px;}
  .font-20{font-size: 18px;}
  .font-24{font-size: 24px;}
  .font-25{font-size: 24px;}
  .font-26{font-size: 24px;}
  .font-27{font-size: 24px;}
  .font-30{font-size: 26px;}
  .font-42{font-size: 42px;}
  .font-50{font-size: 42px;}
  .font-80{font-size: 60px;}
  .font-passage-title{font-size: 18px;}
  .task_bar_task_icon_height{height: 30px;}

  .font-task-bar-name{font-size: 13px;}

  @keyframes animate-header {
    0% {
      transform: translate(-2%, 1010%) scale(2);
    }

    60% {
      transform: translate(-2%, 1010%) scale(2);
    }

    100% {
      transform: translate(0%, 0) scale(1);
    }
  }

  @keyframes theme-1-pre-diagnostic-zoom {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    80% {
        opacity: 1;
        transform: scale(3.0) translate(8em,-8em);
        -webkit-transform: scale(3.0) translate(8em,-8em);
    }
    100% {
        opacity: 0;
        transform: scale(3.0) translate(8em,-8em);
        -webkit-transform: scale(3.0) translate(8em,-8em);
    }
  }

  @keyframes theme-1-mid-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(4.0) translate(-7em,2em);
          -webkit-transform: scale(4.0) translate(-7em,2em);
      }
      100% {
          opacity: 0;
          transform: scale(4.0) translate(-7em,2em);
          -webkit-transform: scale(4.0) translate(-7em,2em);
      }
  }

  @keyframes theme-1-post-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(2.1) translate(-7em,15em);
          -webkit-transform: scale(2.1) translate(-7em,15em);
      }
      100% {
          opacity: 0;
          transform: scale(2.1) translate(-7em,15em);
          -webkit-transform: scale(2.1) translate(-7em,15em);
      }
  }

  @keyframes theme-1-obj1-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(-19em,-15em);
          -webkit-transform: scale(3.0) translate(-19em,-15em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(-19em,-15em);
          -webkit-transform: scale(3.0) translate(-19em,-15em);
      }
  }

  @keyframes theme-1-obj2-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.5) translate(-22em,-4em);
          -webkit-transform: scale(3.5) translate(-22em,-4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.5) translate(-22em,-4em);
          -webkit-transform: scale(3.5) translate(-22em,-4em);
      }
  }

  @keyframes theme-1-obj3-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.3) translate(-28em,4em);
          -webkit-transform: scale(3.3) translate(-28em,4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.3) translate(-28em,4em);
          -webkit-transform: scale(3.3) translate(-28em,4em);
      }
  }

  @keyframes theme-1-obj4-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
  }

  @keyframes theme-1-obj5-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
  }

  @keyframes theme-1-obj6-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(26em,19em);
          -webkit-transform: scale(3.0) translate(26em,19em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(26em,19em);
          -webkit-transform: scale(3.0) translate(26em,19em);
      }
  }

  @keyframes theme-2-pre-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
  }

  @keyframes theme-2-mid-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(26em,19em);
      -webkit-transform: scale(4.0) translate(26em,19em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(26em,19em);
      -webkit-transform: scale(4.0) translate(26em,19em);
    }
  }

  @keyframes theme-2-post-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(2.1) translate(-15em,16em);
      -webkit-transform: scale(2.1) translate(-15em,16em);
    }
    100% {
      opacity: 0;
      transform: scale(2.1) translate(-15em,16em);
      -webkit-transform: scale(2.1) translate(-15em,16em);
    }
  }

  @keyframes theme-2-obj1-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-19em,-15em);
      -webkit-transform: scale(3.0) translate(-19em,-15em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-19em,-15em);
      -webkit-transform: scale(3.0) translate(-19em,-15em);
    }
  }

  @keyframes theme-2-obj2-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.5) translate(9em,-8em);
      -webkit-transform: scale(3.5) translate(9em,-8em);
    }
    100% {
      opacity: 0;
      transform: scale(3.5) translate(9em,-8em);
      -webkit-transform: scale(3.5) translate(9em,-8em);
    }
  }

  @keyframes theme-2-obj3-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.3) translate(20em,4em);
      -webkit-transform: scale(3.3) translate(20em,4em);
    }
    100% {
      opacity: 0;
      transform: scale(3.3) translate(20em,4em);
      -webkit-transform: scale(3.3) translate(20em,4em);
    }
  }

  @keyframes theme-2-obj4-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(13em,14em);
      -webkit-transform: scale(3.0) translate(13em,14em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(13em,14em);
      -webkit-transform: scale(3.0) translate(13em,14em);
    }
  }

  @keyframes theme-2-obj5-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-3em,8em);
      -webkit-transform: scale(3.0) translate(-3em,8em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-3em,8em);
      -webkit-transform: scale(3.0) translate(-3em,8em);
    }
  }

  @keyframes theme-2-obj6-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-18em,5em);
      -webkit-transform: scale(3.0) translate(-18em,5em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-18em,5em);
      -webkit-transform: scale(3.0) translate(-18em,5em);
    }
  }
}

@media (min-height: 800px){
  html, body {
    width: 100%;
    height: 100%;
    min-width: 845px;
    max-width: 1127px;
    margin: 0 auto;
    padding: 0;
    word-wrap: normal;
  }

  .font-11{font-size: 11px;}
  .font-13{font-size: 13px;}
  .font-14{font-size: 14px;}
  .font-15{font-size: 15px;}
  .font-17{font-size: 17px;}
  .font-18{font-size: 18px;}
  .font-20{font-size: 20px;}
  .font-24{font-size: 24px;}
  .font-25{font-size: 25px;}
  .font-26{font-size: 26px;}
  .font-27{font-size: 27px;}
  .font-30{font-size: 30px;}
  .font-42{font-size: 42px;}
  .font-50{font-size: 42px;}
  .font-80{font-size: 60px;}
  .font-passage-title{font-size: 18px;}
  .task_bar_task_icon_height{height: 30px;}

  .font-task-bar-name{font-size: 13px;}

  @keyframes animate-header {
    0% {
      transform: translate(-2%, 960%) scale(1.9);
    }

    60% {
      transform: translate(-2%, 960%) scale(1.9);
    }

    100% {
      transform: translate(0%, 0) scale(1);
    }
  }

  @keyframes theme-1-pre-diagnostic-zoom {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    80% {
        opacity: 1;
        transform: scale(3.0) translate(8em,-8em);
        -webkit-transform: scale(3.0) translate(8em,-8em);
    }
    100% {
        opacity: 0;
        transform: scale(3.0) translate(8em,-8em);
        -webkit-transform: scale(3.0) translate(8em,-8em);
    }
  }

  @keyframes theme-1-mid-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(4.0) translate(-7em,2em);
          -webkit-transform: scale(4.0) translate(-7em,2em);
      }
      100% {
          opacity: 0;
          transform: scale(4.0) translate(-7em,2em);
          -webkit-transform: scale(4.0) translate(-7em,2em);
      }
  }

  @keyframes theme-1-post-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(2.1) translate(-7em,15em);
          -webkit-transform: scale(2.1) translate(-7em,15em);
      }
      100% {
          opacity: 0;
          transform: scale(2.1) translate(-7em,15em);
          -webkit-transform: scale(2.1) translate(-7em,15em);
      }
  }

  @keyframes theme-1-obj1-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(-19em,-15em);
          -webkit-transform: scale(3.0) translate(-19em,-15em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(-19em,-15em);
          -webkit-transform: scale(3.0) translate(-19em,-15em);
      }
  }

  @keyframes theme-1-obj2-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.5) translate(-22em,-4em);
          -webkit-transform: scale(3.5) translate(-22em,-4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.5) translate(-22em,-4em);
          -webkit-transform: scale(3.5) translate(-22em,-4em);
      }
  }

  @keyframes theme-1-obj3-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.3) translate(-28em,4em);
          -webkit-transform: scale(3.3) translate(-28em,4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.3) translate(-28em,4em);
          -webkit-transform: scale(3.3) translate(-28em,4em);
      }
  }

  @keyframes theme-1-obj4-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
  }

  @keyframes theme-1-obj5-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
  }

  @keyframes theme-1-obj6-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(26em,19em);
          -webkit-transform: scale(3.0) translate(26em,19em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(26em,19em);
          -webkit-transform: scale(3.0) translate(26em,19em);
      }
  }

  @keyframes theme-2-pre-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-16em,-9em);
      -webkit-transform: scale(3.0) translate(-16em,-9em);
    }
  }

  @keyframes theme-2-mid-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(26em,19em);
      -webkit-transform: scale(4.0) translate(26em,19em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(26em,19em);
      -webkit-transform: scale(4.0) translate(26em,19em);
    }
  }

  @keyframes theme-2-post-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(2.1) translate(-15em,18em);
      -webkit-transform: scale(2.1) translate(-15em,18em);
    }
    100% {
      opacity: 0;
      transform: scale(2.1) translate(-15em,18em);
      -webkit-transform: scale(2.1) translate(-15em,18em);
    }
  }

  @keyframes theme-2-obj1-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-23em,-13em);
      -webkit-transform: scale(3.0) translate(-23em,-13em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-23em,-13em);
      -webkit-transform: scale(3.0) translate(-23em,-13em);
    }
  }

  @keyframes theme-2-obj2-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.5) translate(9em,-8em);
      -webkit-transform: scale(3.5) translate(9em,-8em);
    }
    100% {
      opacity: 0;
      transform: scale(3.5) translate(9em,-8em);
      -webkit-transform: scale(3.5) translate(9em,-8em);
    }
  }

  @keyframes theme-2-obj3-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.3) translate(22em,4em);
      -webkit-transform: scale(3.3) translate(22em,4em);
    }
    100% {
      opacity: 0;
      transform: scale(3.3) translate(22em,4em);
      -webkit-transform: scale(3.3) translate(22em,4em);
    }
  }

  @keyframes theme-2-obj4-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(16em,15em);
      -webkit-transform: scale(3.0) translate(16em,15em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(16em,15em);
      -webkit-transform: scale(3.0) translate(16em,15em);
    }
  }

  @keyframes theme-2-obj5-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-3em,10em);
      -webkit-transform: scale(3.0) translate(-3em,10em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-3em,10em);
      -webkit-transform: scale(3.0) translate(-3em,10em);
    }
  }

  @keyframes theme-2-obj6-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-15em,5em);
      -webkit-transform: scale(3.0) translate(-15em,5em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-15em,5em);
      -webkit-transform: scale(3.0) translate(-15em,5em);
    }
  }
}

@media (max-height: 849px) {
  .root-word-top-dictionary-definition,
  .root-word-bottom-dictionary-definition {
    line-height: 15px !important ;
    font-size: 13px !important ;
  }
}

@media (min-height: 850px){
  html, body {
    width: 100%;
    height: 100%;
    min-width: 845px;
    max-width: 1198px;
    margin: 0 auto;
    padding: 0;
    word-wrap: normal;
  }

  .font-11{font-size: 11px;}
  .font-13{font-size: 13px;}
  .font-14{font-size: 14px;}
  .font-15{font-size: 15px;}
  .font-17{font-size: 17px;}
  .font-18{font-size: 18px;}
  .font-20{font-size: 20px;}
  .font-24{font-size: 24px;}
  .font-25{font-size: 25px;}
  .font-26{font-size: 26px;}
  .font-27{font-size: 27px;}
  .font-30{font-size: 30px;}
  .font-42{font-size: 42px;}
  .font-50{font-size: 50px;}
  .font-80{font-size: 80px;}
  .font-passage-title{font-size: 18px;}
  .task_bar_task_icon_height{height: 30px;}

  .font-task-bar-name{font-size: 15px;}

  @keyframes animate-header {
    0% {
      transform: translate(-2%, 1025%) scale(2);
    }

    60% {
      transform: translate(-2%, 1025%) scale(2);
    }

    100% {
      transform: translate(0%, 0) scale(1);
    }
  }

  @keyframes theme-1-pre-diagnostic-zoom {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    80% {
        opacity: 1;
        transform: scale(3.0) translate(8em,-10em);
        -webkit-transform: scale(3.0) translate(8em,-10em);
    }
    100% {
        opacity: 0;
        transform: scale(3.0) translate(8em,-15em);
        -webkit-transform: scale(3.0) translate(8em,-10em);
    }
  }

  @keyframes theme-1-mid-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.5) translate(-9em,3em);
          -webkit-transform: scale(3.5) translate(-9em,3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.5) translate(-9em,3em);
          -webkit-transform: scale(3.5) translate(-9em,3em);
      }
  }

  @keyframes theme-1-post-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(2.1) translate(-7em,16em);
          -webkit-transform: scale(2.1) translate(-7em,16em);
      }
      100% {
          opacity: 0;
          transform: scale(2.1) translate(-7em,16em);
          -webkit-transform: scale(2.1) translate(-7em,16em);
      }
  }

  @keyframes theme-1-obj1-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(-22em,-17em);
          -webkit-transform: scale(3.0) translate(-22em,-17em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(-22em,-17em);
          -webkit-transform: scale(3.0) translate(-22em,-17em);
      }
  }

  @keyframes theme-1-obj2-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.5) translate(-24em,-4em);
          -webkit-transform: scale(3.5) translate(-24em,-4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.5) translate(-24em,-4em);
          -webkit-transform: scale(3.5) translate(-24em,-4em);
      }
  }

  @keyframes theme-1-obj3-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.3) translate(-30em,4em);
          -webkit-transform: scale(3.3) translate(-30em,4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.3) translate(-30em,4em);
          -webkit-transform: scale(3.3) translate(-30em,4em);
      }
  }

  @keyframes theme-1-obj4-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
  }

  @keyframes theme-1-obj5-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
  }

  @keyframes theme-1-obj6-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(28em,19em);
          -webkit-transform: scale(3.0) translate(28em,19em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(28em,19em);
          -webkit-transform: scale(3.0) translate(28em,19em);
      }
  }

  @keyframes theme-2-pre-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(8em,-10em);
      -webkit-transform: scale(3.0) translate(8em,-10em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(8em,-15em);
      -webkit-transform: scale(3.0) translate(8em,-10em);
    }
  }

  @keyframes theme-2-mid-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.5) translate(26em,18em);
      -webkit-transform: scale(3.5) translate(26em,18em);
    }
    100% {
      opacity: 0;
      transform: scale(3.5) translate(26em,18em);
      -webkit-transform: scale(3.5) translate(26em,18em);
    }
  }

  @keyframes theme-2-post-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(2.1) translate(-20em,18em);
      -webkit-transform: scale(2.1) translate(-20em,18em);
    }
    100% {
      opacity: 0;
      transform: scale(2.1) translate(-20em,18em);
      -webkit-transform: scale(2.1) translate(-20em,18em);
    }
  }

  @keyframes theme-2-obj1-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-22em,-17em);
      -webkit-transform: scale(3.0) translate(-22em,-17em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-22em,-17em);
      -webkit-transform: scale(3.0) translate(-22em,-17em);
    }
  }

  @keyframes theme-2-obj2-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
  }

  @keyframes theme-2-obj3-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.3) translate(22em,4em);
      -webkit-transform: scale(3.3) translate(22em,4em);
    }
    100% {
      opacity: 0;
      transform: scale(3.3) translate(22em,4em);
      -webkit-transform: scale(3.3) translate(22em,4em);
    }
  }

  @keyframes theme-2-obj4-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(13em,15em);
      -webkit-transform: scale(3.0) translate(13em,15em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(13em,15em);
      -webkit-transform: scale(3.0) translate(13em,15em);
    }
  }

  @keyframes theme-2-obj5-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-3em,10em);
      -webkit-transform: scale(3.0) translate(-3em,10em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-3em,10em);
      -webkit-transform: scale(3.0) translate(-3em,10em);
    }
  }

  @keyframes theme-2-obj6-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-30em,4em);
      -webkit-transform: scale(3.0) translate(-30em,4em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-30em,4em);
      -webkit-transform: scale(3.0) translate(-30em,4em);
    }
  }
}

@media (min-height: 894px){
  html, body {
    width: 100%;
    height: 100%;
    /* max-height: 769px; */
    min-width: 845px;
    max-width: 1260px;
    margin: 0 auto;
    padding: 0;
    word-wrap: normal;
  }

  .font-11{font-size: 11px;}
  .font-13{font-size: 13px;}
  .font-14{font-size: 14px;}
  .font-15{font-size: 15px;}
  .font-17{font-size: 17px;}
  .font-18{font-size: 18px;}
  .font-20{font-size: 20px;}
  .font-24{font-size: 24px;}
  .font-25{font-size: 25px;}
  .font-26{font-size: 26px;}
  .font-27{font-size: 27px;}
  .font-30{font-size: 30px;}
  .font-42{font-size: 42px;}
  .font-50{font-size: 50px;}
  .font-80{font-size: 80px;}
  .font-passage-title{font-size: 18px;}
  .task_bar_task_icon_height{height: 30px;}

  .font-task-bar-name{font-size: 15px;}

  @keyframes animate-header {
    0% {
      transform: translate(-2%, 1085%) scale(2);
    }

    60% {
      transform: translate(-2%, 1085%) scale(2);
    }

    100% {
      transform: translate(0%, 0) scale(1);
    }
  }

  @keyframes theme-1-pre-diagnostic-zoom {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    80% {
        opacity: 1;
        transform: scale(3.0) translate(8em,-10em);
        -webkit-transform: scale(3.0) translate(8em,-10em);
    }
    100% {
        opacity: 0;
        transform: scale(3.0) translate(8em,-15em);
        -webkit-transform: scale(3.0) translate(8em,-10em);
    }
  }

  @keyframes theme-1-mid-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.5) translate(-9em,3em);
          -webkit-transform: scale(3.5) translate(-9em,3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.5) translate(-9em,3em);
          -webkit-transform: scale(3.5) translate(-9em,3em);
      }
  }

  @keyframes theme-1-post-diagnostic-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(2.1) translate(-7em,16em);
          -webkit-transform: scale(2.1) translate(-7em,16em);
      }
      100% {
          opacity: 0;
          transform: scale(2.1) translate(-7em,16em);
          -webkit-transform: scale(2.1) translate(-7em,16em);
      }
  }

  @keyframes theme-1-obj1-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(-22em,-17em);
          -webkit-transform: scale(3.0) translate(-22em,-17em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(-22em,-17em);
          -webkit-transform: scale(3.0) translate(-22em,-17em);
      }
  }

  @keyframes theme-1-obj2-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.5) translate(-24em,-4em);
          -webkit-transform: scale(3.5) translate(-24em,-4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.5) translate(-24em,-4em);
          -webkit-transform: scale(3.5) translate(-24em,-4em);
      }
  }

  @keyframes theme-1-obj3-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.3) translate(-30em,4em);
          -webkit-transform: scale(3.3) translate(-30em,4em);
      }
      100% {
          opacity: 0;
          transform: scale(3.3) translate(-30em,4em);
          -webkit-transform: scale(3.3) translate(-30em,4em);
      }
  }

  @keyframes theme-1-obj4-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(13em,8em);
          -webkit-transform: scale(3.0) translate(13em,8em);
      }
  }

  @keyframes theme-1-obj5-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(24em,-3em);
          -webkit-transform: scale(3.0) translate(24em,-3em);
      }
  }

  @keyframes theme-1-obj6-zoom {
      0% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
      80% {
          opacity: 1;
          transform: scale(3.0) translate(28em,19em);
          -webkit-transform: scale(3.0) translate(28em,19em);
      }
      100% {
          opacity: 0;
          transform: scale(3.0) translate(28em,19em);
          -webkit-transform: scale(3.0) translate(28em,19em);
      }
  }

  @keyframes theme-2-pre-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(8em,-10em);
      -webkit-transform: scale(3.0) translate(8em,-10em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(8em,-15em);
      -webkit-transform: scale(3.0) translate(8em,-10em);
    }
  }

  @keyframes theme-2-mid-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.5) translate(28em,18em);
      -webkit-transform: scale(3.5) translate(28em,18em);
    }
    100% {
      opacity: 0;
      transform: scale(3.5) translate(28em,18em);
      -webkit-transform: scale(3.5) translate(28em,18em);
    }
  }

  @keyframes theme-2-post-diagnostic-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(2.1) translate(-20em,20em);
      -webkit-transform: scale(2.1) translate(-20em,20em);
    }
    100% {
      opacity: 0;
      transform: scale(2.1) translate(-20em,20em);
      -webkit-transform: scale(2.1) translate(-20em,20em);
    }
  }

  @keyframes theme-2-obj1-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-22em,-17em);
      -webkit-transform: scale(3.0) translate(-22em,-17em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-22em,-17em);
      -webkit-transform: scale(3.0) translate(-22em,-17em);
    }
  }

  @keyframes theme-2-obj2-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
    100% {
      opacity: 0;
      transform: scale(4.0) translate(9em,-8em);
      -webkit-transform: scale(4.0) translate(9em,-8em);
    }
  }

  @keyframes theme-2-obj3-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.3) translate(22em,4em);
      -webkit-transform: scale(3.3) translate(22em,4em);
    }
    100% {
      opacity: 0;
      transform: scale(3.3) translate(22em,4em);
      -webkit-transform: scale(3.3) translate(22em,4em);
    }
  }

  @keyframes theme-2-obj4-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(16em,15em);
      -webkit-transform: scale(3.0) translate(16em,15em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(16em,15em);
      -webkit-transform: scale(3.0) translate(16em,15em);
    }
  }

  @keyframes theme-2-obj5-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-3em,10em);
      -webkit-transform: scale(3.0) translate(-3em,10em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-3em,10em);
      -webkit-transform: scale(3.0) translate(-3em,10em);
    }
  }

  @keyframes theme-2-obj6-zoom {
    0% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
    80% {
      opacity: 1;
      transform: scale(3.0) translate(-18em,5em);
      -webkit-transform: scale(3.0) translate(-18em,5em);
    }
    100% {
      opacity: 0;
      transform: scale(3.0) translate(-18em,5em);
      -webkit-transform: scale(3.0) translate(-18em,5em);
    }
  }
}

.unit-number{
  height: 36%;
  width: 35%;
  position: relative;
  left: 14%;
  top: 20%;
  font-family: Helvetica;
  font-weight: bold;
  text-align: center;
  line-height: 100%;
  letter-spacing: 3px;
  color: rgb(255, 255, 255, 0.80);
}

.passage-title {
  font-family: Helvetica;
  color: #4A4A4A;
  float: left;
  margin-left: 2vh;
  margin-top: 0.5vh;
  position: absolute;
  left: 11%;
}

.make-the-response-block {
  height: 100%;
  width: 70%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 125%;
  border-radius: 20px;
  outline: none; }

.answer-the-question-response-row {
  width: 90%;
  height: 20%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center; }

.answer-the-question-target{
  width: 88%;
  text-align: left;
  line-height: 125%;
  position: absolute;
  top: 20%;
  left: 11%;
}

.answer-the-question-big-button-div {
  display: flex;
  height: 100%;
  width: 46%;
  margin-right: 2%;
  margin-left: 2%;
  justify-content: flex-start;
  align-items: flex-start;
}

.answer-the-question-div-letter-audio {
  width: 17%;
  padding-top: 10%;
  position: relative;
  top: -8%;
  left: 13%;
  margin: 3px;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

.answer-the-question-div-letter-audio-hidden {
  width: 17%;
  padding-top: 10%;
  position: relative;
  top: -8%;
  left: 13%;
  margin: 3px;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.scrollable-sentence-container {
  height: 65%;
  width: 90%;
  margin-top: 1%;
  margin-left: 5%;
  overflow: auto;
  display: inline-block;
}

.total-points-score-task {
  font-family: Helvetica;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3.93px;
  pointer-events: auto;
  height: 70%;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.total-points-label-task {
  font-family: Helvetica;
  color: #4A4A4A;
  letter-spacing: 3.6px;
  pointer-events: auto;
  line-height: 100%;
  top: -5px;
  position: relative;
}


.task-cloud-passage-title {
  font-family: Helvetica;
  font-weight: bold;
  color: #184760;
  letter-spacing: 3.6px;
  pointer-events: auto;
  width: 100%;
  white-space: pre;
  right: 3%;
}

.task-cloud-task-points {
  font-family: Helvetica;
  color: #4A4A4A;
  pointer-events: auto;
  position: absolute;
  width: 100%;
}

.task-cloud-task-score {
  font-family: Helvetica;
  color: #4A4A4A;
  pointer-events: auto;
  position: absolute;
  width: 100%;
}

.passages-target {
  width: 80%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 300%;
  border-radius: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: auto;
}

.passages-dialog-target-adjust {
  position: relative;
  top: 20%;
}



.response-block {
  height: 80%;
  width: 39%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  outline: none; }

.response-block-grayed {
  height: 80%;
  width: 39%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  outline: none;
  opacity: 0.4; }

.silent-e {
  height: 33%;
  width: 18%;
  position: relative;
  right: 9%;
  bottom: 40%;
  margin: 3px;
  padding: 4px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 50%;
  border-radius: 10px;
  border: 4px white;
  outline: none; }

.silent-e-grayed {
  height: 30%;
  width: 18%;
  position: relative;
  right: 9%;
  bottom: 40%;
  margin: 3px;
  padding: 4px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 50%;
  border-radius: 10px;
  border: 4px white;
  outline: none;
  opacity: 0.4; }

.silent-e-hidden {
  height: 30%;
  width: 18%;
  position: relative;
  right: 9%;
  bottom: 40%;
  margin: 3px;
  padding: 4px;
  background: transparent;
  border: none;
  outline: none;
  cursor: default; }

.target-text {
  width: 100%;
  position: relative;
  top: 25%;
  background: transparent;
  border: 6px transparent;
  vertical-align: middle;}

.consonant{
  width: 3.7%;
  display: inline-block;
  background: #195DAC;
  border: 6px transparent;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  vertical-align: center;
  text-align: center;
  line-height: 150%;
  color: #4990E2;
  margin-right: 1%;
  margin-left: 1%;
  outline: none;
}

.vowel{
  width: 3.7%;
  display: inline-block;
  background: #D0011B;
  border: 6px transparent;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  vertical-align: center;
  text-align: center;
  line-height: 150%;
  color: #E47E8B;
  margin-right: 1%;
  margin-left: 1%;
  outline: none;
}

.letter-type-hidden{
  width: 3.7%;
  display: inline-block;
  background: transparent;
  border: 6px transparent;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px transparent;
  vertical-align: center;
  text-align: center;
  line-height: 150%;
  color: transparent;
  margin-right: 1%;
  margin-bottom: 1%;
  outline: none;
}

.keyboard-letters{
  pointer-events: none;
}

.keyboard-letters-hidden{
  pointer-events: none;
  color: transparent;
  border: none !important;
  font-size: 0;
}

.keyboard-key{
  width: 7%;
  height: 42px;
  display: inline-block;
  margin: 0.5%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  border-radius: 1.5vh;
  line-height: 270%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  outline: none;
}

.keyboard-key-hidden{
  width: 7%;
  height: 42px;
  display: inline-block;
  margin: 0.5%;
  background: transparent;
  color: transparent;
  border: none !important;
  font-size: 0;
  border-radius: 1.5vh;
  line-height: 270%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px transparent;
  outline: none;
  pointer-events: none;
}

.keyboard-key.hover,
.keyboard-key:hover {
  border: 6px solid;
  line-height:180%;
}

.keyboard-shift{
  width: 12%;
  height: 45px;
  display: block;
  margin: 0.5%;
  background: #E5E5E5;
  border: 6px #E5E5E5;
  padding: 6px;
  border-radius: 1.5vh;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  outline: none;
  color: #4A4A4A;
}

.keyboard-shift-hidden{
  width: 17%;
  height: 45px;
  display: block;
  margin: 0.5%;
  background: transparent;
  border: 6px transparent;
  padding: 6px;
  border-radius: 1.5vh;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px transparent;
  text-align: center;
  outline: none;
  pointer-events: none;
}

.keyboard-shift.caps-lock-on {
  border-color: #c44040 !important;
  border-style: solid;
}

.keyboard-delete{
  width: 12%;
  height: 45px;
  display: block;
  margin: 0.5%;
  background: #E5E5E5;
  border: 6px #E5E5E5;
  padding: 6px;
  border-radius: 1.5vh;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  outline: none;
  color: #4A4A4A;
}

.keyboard-delete-hidden{
  width: 17%;
  height: 45px;
  display: block;
  margin: 0.5%;
  background: transparent;
  border: 6px transparent;
  padding: 6px;
  border-radius: 1.5vh;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px transparent;
  text-align: center;
  outline: none;
  pointer-events: none;
}

.keyboard-enter{
  width: 15%;
  height: 45px;
  display: block;
  margin: 0.5%;
  background: #A4C583;
  border: 6px #A4C583;
  padding: 6px;
  border-radius: 1.5vh;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  outline: none;
  color: #4A4A4A;
}

.keyboard-enter-disabled {
  width: 15%;
  height: 45px;
  display: block;
  margin: 0.5%;
  background: #A4C583;
  border: 6px #A4C583;
  opacity: 0.5;
  padding: 6px;
  border-radius: 1.5vh;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  outline: none;
  color: #4A4A4A;
}

.keyboard-enter-hidden{
  width: 15%;
  height: 45px;
  display: block;
  margin: 0.5%;
  background: transparent;
  border: 6px transparent;
  padding: 6px;
  border-radius: 1.5vh;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px transparent;
  text-align: center;
  outline: none;
  pointer-events: none;
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 71%;
  background: white;
  position: relative;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flexbox-centering {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewport-1
{
  background-size: cover;
  margin: auto;
  position: absolute;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;  /* no scrollbars */
  width: 100%;
}

.fireworks-panel {
  position: inherit;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  z-index: 9999;
  /* Optional: Adjust the z-index value as needed */
}

.task-select-content-div{
  width: 70%;
  height: 80%;  /* in case css3 not supported */
  height: calc(99% - 10vw);  /* height of header and task bar and a little less to acocunt for margin */
  position: absolute;
  overflow: hidden;
  right: 0;
  padding: 2%;
}

.content-div
{
  width: 100%;
  height: 80%;  /* in case css3 not supported */
  height: calc(99% - 10vw);  /* height of header and task bar and a little less to acocunt for margin */
  position: relative;
  overflow: hidden;
}

.level-select-content-div
{
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  margin: auto;
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  pointer-events: none;
}

.level-div{
  width: 100%;
  height: 80%;
  height: calc(100% - 100px); /* height of header */
  pointer-events: none;
}

.avatar-div{
  position: absolute;
  width: 4%;
  height: 8%;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  pointer-events: auto;
  border: none;
  outline: none;
  z-index: 1001;
}

.level-btn{
  position: absolute;
  width: 5%;
  height: 6%;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  pointer-events: auto;
  border: none;
  outline: none;
  z-index: 901;
}

.screen-left{
  left: 100%;
}

.screen-right{
  left: -100%;
}

.screen-up{
  top: 100%;
}

.screen-down{
  top: -100%;
}

.screen-show{
  left: 0;
  top: 0;
}

.screen-hide{
  left: 100%;
  top: 100%;
}

@keyframes slideInDown {
  0% {
    transform: translate(0px, -60vw);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.destination-all-complete {
  -webkit-animation: slideInDown 2s;
  -moz-animation: slideInDown 2s;
  -o-animation: slideInDown 2s;
  animation: slideInDown 2s;
}

.destination-all-complete-dialog {
  background-color: rgba(231, 244, 243, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin: auto;
  top: 35vh;
  position: relative;
  width: 500px;
  width: 40vw;
  height: 250px;
  height: 30vh;
  padding-top: 90px;
  padding-top: 10vh;
}

.full-opacity {
  opacity: 1;
}

.inner-div
{
  width: 100vw;
  height: 75vw;
  max-height: 100vh;
  max-width: 150vh;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  pointer-events: none;
}

/* Destination buttons on map screen */
.completed {
  -webkit-filter: grayscale(1);
  filter: grayscale(1); }

/* level goal counter styles */
.level-counter-container {
  width: 90%;
  height: 13.5%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.trial-counter-background {
  height: 35%;
  width: 87%;
  display: inline-block;
  border: 2px solid #FFFFFF;
  border-radius: 43px;
}

.trial_counter_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

/* Trial counter styles */
.trial-counter-container {
  width: 90%;
  height: 13.5%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.trial-counter-background {
  height: 35%;
  width: 87%;
  display: inline-block;
  border: 2px solid #FFFFFF;
  border-radius: 43px;
}

.trial-counter-space {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  text-align: center;
}

.trial-counter-space.incomplete {
  content: url(../assets/images/trialcounter/trialCounter_indent.svg);
}

.trial-counter-space.neutral-incomplete {
  content: url(../assets/images/trialcounter/trialCounterNeutral_indent.svg);
}

.trial-counter-space.filled {
  content: url(../assets/images/trialcounter/trialCounterNeutral_filled.svg);
}

.trial-counter-space.correct {
  content: url(../assets/images/trialcounter/trialCounter_correct.svg);
}

.trial-counter-space.incorrect {
  content: url(../assets/images/trialcounter/trialCounter_incorrect.svg);
}

.trial-counter-avatar {
  width: 13%;
  height: 100%;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* The space needed to place images within the trial counter at the start */
.trial-counter-left-margin-space {
  width: 10px;
  display: inline-block;
}

.hide-overflow {
  overflow: hidden;
}

.task-screen-element {
  transition: opacity 0.5s linear;
  height: 50px;
  width: 100%;
  padding: 0.5%;
  user-select: none ;
}

.task-screen-element.taskShow {
  opacity:1;
}

.task-screen-element.taskHide {
  opacity:0;
}

.response-container{
  position: relative;
  top: -9%;
}

/* Fill in the blank screen */
.fill-in-blank-container {
  width: 90%;

  height: 63%;
  margin: auto;
  margin-top: 1vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

.spelling-container {
  width: 90%;
  height: 63%;
  /* padding-bottom: 44%; */
  margin: auto;
  margin-top: 1vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

.word-to-complete {
  width: 95%;
  height: 20%;
  margin: auto;
  margin-bottom: 2%;
  position: relative;
  border: 1px solid green;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.word-to-complete-unit {
  width: 95%;
  height: 34%;
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  border: 1px solid green;
  margin-bottom: 2%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  left: -1%;
}

.make-the-word-to-complete {
  width: 95%;
  height: 30%;
  margin: auto;
  position: relative;
  border: 1px solid green;
  top: 5%;
  margin-bottom: 6vh;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;}

.question-area {
  width: 95%;
  height: 25%;
  margin: auto;
  position: relative;
  border: 1px solid green;
  top: 8%;
  margin-bottom: 6%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;}

.response-row {
  width: 100%;
  height: 20%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;}

.full-response-row {
  width: 100%;
  height: 20%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.full-response-row-picture {
  width: 100%;
  height: 40%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: top; }

.replayVideo {
  width: 5%;
  padding-top: 4%;
  position: relative;
  top: -44px;
  background-image: url(../assets/images/buttons/videoReplay.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  float: right;
  outline: none;
  border: none;
}

.replayVideo-no-timerbar {
  width: 5%;
  padding-top: 4%;
  position: relative;
  background-image: url(../assets/images/buttons/videoReplay.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  float: right;
  outline: none;
  border: none;
}

.open-passage {
  width: 5%;
  padding-top: 4%;
  background-image: url(../assets/images/buttons/passageBttn.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  float: left;
  outline: none;
  border: none;
}

.read-the-word-target-container {
  width: 95%;
  height: 40%;
  margin: auto;
  margin-bottom: 4%;
  position: relative;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.read-the-sentence-target-container {
  width: 90%;
  height: 50%;
  margin: auto;
  margin-bottom: 2%;
  position: relative;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.recording-playback-container {
  background-color: #ffffff80;
  border: 2px solid #4A4A4A;
  display: flex;
  margin: auto;
  border-radius: 10px;
  width: 50%;
  padding: 1em 2em 0.5em 2em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.progress-bar-rtw {
  width: 100%;
  height: 4px;
  background-color: #00000070;
  cursor: pointer;
}

.progress-bar-container {
  width: 100%;
  height: 4px;
  border-radius: 10px;
  padding: 1em;
  margin-bottom: 0.5em;
}

.playback-progress {
  position: relative;
  height: 4px;
  background-color: #FFFFFF;
  cursor: pointer;
  transition: linear 0.1s;
}

.playback-timestamps {
  display: flex;
  justify-content: space-between;
  color: #4a4a4a;
  font-weight: bold;
}

.playback-progress::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  right: -5px;
  /* half of the width and height */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.record-button {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/recordBttn_bu.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.record-button-active {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/recordBttn_bd.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
  pointer-events: none;
}

.record-button-rtw {
  width: 8.0%;
  height: 100%;
  background-image: url(../assets/images/buttons/recordBttn_bu.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.record-button-active-rtw {
  width: 8.0%;
  height: 100%;
  background-image: url(../assets/images/buttons/recordBttn_bd.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.record-button-disabled {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/recordBttn_bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
  pointer-events: none;
}

.record-button-stop-rtw {
  width: 8.0%;
  height: 100%;
  background-image: url(../assets/images/buttons/stopBttn_bd.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.record-button-stop {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/recordBttn_bd.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
  pointer-events: none;
}

.sentence-type-display {
  width: 100%;
}

.button-disabled {
  pointer-events: none;
  transition: 0.5s;
  opacity: 50%;
}

.testType {
  font-family: Helvetica;
  font-weight: bold;
  color: #4A4A4A;
  float: left;
  padding-left: 30px;
  height: 46px;
}

.speaker {
  width: 8%;
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: 1;
  background-image: url(../assets/images/audioBttn.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-position: center;
  margin-left: 1%;
  background-color: transparent;
  outline: none;
  border: none; }

.speaker-passages-adjust {
  margin-top: 1%;
}

.speaker-rtw-adjust {
  scale: 0.8;
  top: 0;
  height: 30%;
  margin: 0;

  &:disabled {
    filter: grayscale(0.3);
    opacity: 30%;
    pointer-events: none;
    transition: 0.5s;
  }
}

.submit-rtw {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1em;
  background-size: 60%;
  width: 50px;
  height: 50px;
  outline: none;
  padding: 6px;
  box-sizing: border-box;
  border: #81BE43;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
  border-radius: 11px;
  background-image: url(../assets/images/buttons/submitBttnShape.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #81BE43;
}

.submit-rts {
  background-size: 65%;
  width: 62px;
  height: 62px;
  outline: none;
  border: #81BE43;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
  border-radius: 12px;
  background-image: url(../assets/images/buttons/submitBttnShape.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #81BE43;
}

.retry-button {
  width: 62px;
  height: 62px;
  outline: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
  border-radius: 12px;
  background-image: url(../assets/images/buttons/respBttnTryAgain.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #947cd1;
  border: none;
  color: white;
}

.no-button-rts {
  width: 62px;
  height: 62px;
  outline: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
  border-radius: 12px;
  background-image: url(../assets/images/buttons/respBttnNo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #da2f18;
  background-size:  100% ;
  border: none;
}

.retry-button[disabled] {
  filter: grayscale(0.6);
  pointer-events: none;
  transition: 0.5s;
  opacity: 50%;
}

.submit-rtw[disabled] {
  filter: grayscale(0.8);
  pointer-events: none;
  transition: 0.5s;
}

.submit-rts[disabled] {
  filter: grayscale(0.6);
  pointer-events: none;
  transition: 0.5s;
  opacity: 50%;
}

.no-button-rts[disabled] {
  filter: grayscale(0.6);
  pointer-events: none;
  transition: 0.5s;
  opacity: 50%;
}

.raise-speaker {
  width: 10%;
  padding-top: 7%;
  position: absolute;
  left: 0px;
  top: 6%;
  z-index: 1;
  background-image: url(../assets/images/audioBttn.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  margin-top: 2.5vh;
  margin-left: 1%;
  background-color: transparent;
  outline: none;
  border: none; }

.speaker-no-target-area {
  width: 7%;
  padding-top: 7%;
  position: absolute;
  left: 0px;
  z-index: 1;
  background-image: url(../assets/images/audioBttn.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  margin-left: 1%;
  background-color: transparent;
  outline: none;
  border: none; }

  .spell-the-word-target-text {
    height: 10vh;
    width: 10vh;
    background: #FFFFFF;
    border: 6px #FFFFFF;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    vertical-align: middle;
    line-height: 10vh;
    border-radius: 2vh;
    font-size: 5vh;
    outline: none; }

  .spell-the-word-target-block{
    position: relative;
    top: -4vh;}

  .spell-the-word-target-block-hidden {
    height: 10vh;
    width: 10vh;
    display: inline-block;
    background: #FFFFFF;
    margin: 1vh;
    text-align: center;
    vertical-align: middle;
    line-height: 10vh;
    border-radius: 2vh;
    margin-top: 1.5vh;
    font-size: 5vh; }



#keyPress:focus {
  outline: 0;
  outline: none;
}

.keyboard-container{
  width: 80%;
  margin: auto;
  margin-top: 3%;
  text-align: center;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  border: 2px solid #4A4A4A;
  padding: 0.5%;
  padding-right: 4%;
}

.keyboard-container{
  width: 80%;
  margin: auto;
  margin-top: 3%;
  text-align: center;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #4A4A4A;
  padding: 0.5%;
  padding-right: 4%;
}

.keyboard-row{
  width: 100%;
  height: 33%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.special-text{
  position: relative;
  left: -25%;
  top: 0%;
  color: #4A4A4A;
  pointer-events: none;
}

.special-text.right{
  left: -14%;
  text-align: right;
}



.keyboard-enter.hover,
.keyboard-shift.hover,
.keyboard-delete.hover,
.keyboard-letters.hover{
  border: 6px solid; padding: 0px;
}

.spelling-letter-audio {
  padding-top: 4%;
  width: 4%;
  margin: 3px;
  margin-left: 4%;
  margin-right: 4%;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

.spelling-letter-audio-hidden {
  padding-top: 4%;
  width: 4%;
  margin: 3px;
  display: inline-block;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.spelling-target-area {
  width: 95%;
  height: 35%;
  margin: auto;
  margin-bottom: 1%;
  margin-top: 4%;
  position: relative;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center; }

.spelling-target-block{
  display: inline-block;
  width: 3.7%;
  margin-left: 1%;
  margin-right: 1%;}

.spelling-target-block-hidden {
  height: 5vh;
  width: 5vh;}

.spelling-target-text {
  width: 100%;
  background: transparent;
  border: 6px transparent;
  text-align: center;
  vertical-align: middle;
  color:#4A4A4A;
  line-height: 150%;}

.spelling-letter-space-hidden{
  width: 3.7%;
  display: inline-block;
  text-align: center;
  line-height: 100%;
  color: transparent;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 1%;
}

.spelling-letter-space{
  width: 3.7%;
  display: inline-block;
  text-align: center;
  line-height: 100%;
  color: #4A4A4A;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 1%;
  background-color: transparent;
  outline: none;
  border: 0;
}

.spelling-empty-keyboard-space{
  display: inline-block;
  width: 13%;
}

.spelling-target-row{
  height: 25%;
  width: 100%;
  margin-bottom: 1%;
  margin-top: 1%;
  position: relative;
}

.spelling-target-highlight{
  height: 100%;
  position: absolute;
  background-color: #6363633e;
  max-width: 90%;
  min-width: 25%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
}

.spelling-help-row{
  height: 20%;
  width: 100%;
}

.spelling-type-text{
  position: relative;
  top: 1vh;
}

.star-submit {
  height: 27%;
  width: 5%;
  position: relative;
  left: -2%;
  top: 25%;
  outline: none;
  padding: 6px;
  background-size: contain;
  box-sizing: border-box;
  border: #81BE43;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 11px;
  background-image: url(../assets/images/buttons/submitBttnShape.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #81BE43;
  background-size: 80%; }

.star-submit-disabled {
  height: 27%;
  width: 5%;
  position: relative;
  left: -2%;
  top: 25%;
  outline: none;
  padding: 6px;
  background-size: contain;
  box-sizing: border-box;
  border: #81BE43;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 11px;
  background-image: url(../assets/images/buttons/submitBttnShape.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #81BE43;
  background-size: 80%;
  opacity: 0.4; }

.diagnostic-target-text {
  width: 100%;
  position: relative;
  top: 25%;
  background: transparent;
  border: 6px transparent;
  vertical-align: middle;}

.ctw-target-text {
  position: relative;
  top: 10%;
  width: 100%;
  background: transparent;
  border: 6px transparent;
  text-align: center;
  vertical-align: middle;
  line-height: 100%;
}

/** Attempts to fix the positioning of target text when tiles are highlighted **/
.position-target{
  height: 100%;
  width: 35%;
  display: inline-block;
}

.intervention-target-block{
  position: relative;
}

.intervention-target-block-hidden {
  height: 10vh;
  width: 10vh;
  display: inline-block;
  background: #FFFFFF;
  margin: 1vh;
  text-align: center;
  vertical-align: middle;
  line-height: 10vh;
  border-radius: 2vh;
  margin-top: 1.5vh;
  font-size: 5vh; }

.incorrect-row{
  height: 23%;
  width: 100%;
}

.target-row{
  width: 100%;
  height: 50%;
}

.target-row-prepost{
  width: 100%;
  height: 90%;
}

.diagnostic-target-row{
  width: 100%;
  height: 100%;
  margin-top: 1%;
}

.ctw-row{
  width: 100%;
  height: 70%;
}

.target-word-row{
  width: 100%;
  height: 40%;
}

.target-sentence-row{
  width: 100%;
  display: inline-flex;
  justify-content: center;
  gap: 0.5em;
  flex-wrap: wrap;
  margin: 5px;
}

.target-word-rtw-text {
  margin: auto;
  font-size: 40px;
  text-align: center;
  vertical-align: middle;
}

.target-sentence-rts-text {
  font-size: 1.35em;
  display: inline-flex;
  align-items: center;
}

.rts-word-correct {
  color: rgb(87, 181, 87);
  transition: 0.5s;
}

.rts-word-incorrect {
  color: rgb(200, 83, 83);
  transition: 0.5s;
}

.rts-word-caution {
  color: rgb(213, 164, 92);
  transition: 0.5s;
}

.sentence-type-row {
  display: flex;
  scale: 0.75;
}

.recording-button-row {
  width: 100%;
  height: 34%;
  position: absolute;
  margin: 1em;
  bottom: 0;
}

.recording-button-row-rts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  padding-bottom: 10px;
}

.recording-playback-button {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/playBttn_bu.svg);

  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.recording-playback-button[disabled] {
  background-image: url(../assets/images/buttons/playBttn_bg.svg);
  pointer-events: none;
  transition: 0.5s;
}

.recording-playback-button-pause[disabled] {
  background-image: url(../assets/images/buttons/playBttn_bg.svg);
  filter: grayscale(0.3);
  opacity: 30%;
  pointer-events: none;
  transition: 0.5s;
}

.recording-playback-button-disabled {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/playBttn_bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.recording-playback-button-pause {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/playBttn_bd.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.recording-playback-button-pause-disabled {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/playBttn_bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
  pointer-events: none;
}

.recording-playback-button-stop {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/stopBttn_bd.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
}

.recording-playback-button-stop-disabled {
  width: 50px;
  height: 50px;
  background-image: url(../assets/images/buttons/stopBttn_bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50px;
  pointer-events: none;
}

.recording-playback-button-container {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 15px;
  margin-top: 8px;
  padding: 6px;
}

.inset-container {
  box-shadow: inset 0px 2px 3px 1px rgba(0, 0, 0, 0.4);
  background-color: rgba(256, 256, 256, 0.4);
}

.bouncing-dots-row {
  display: inline-flex;
  height: 40%;
}

.bouncing-dots-row-rts {
  height: auto;
  padding-bottom: 0;
}

.bouncing-dots {
  display: flex;
  justify-content: space-between;
  width: 60px;
  position: relative;
}

.bouncing-dots .dot {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.bouncing-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-20px);
  }
}

.target-incorrect {
  position: relative;
  width: 8%;
  display: inline-block;
  background: transparent;
  margin: 1%;
  vertical-align: middle;
}

.target-incorrect-text{
  text-align: center;
  vertical-align: middle;
  line-height: 100%;
  color: grey;
  padding: 6px;
}

.stw-letter-audio {
  height: 4vh;
  width: 4vh;
  position: relative;
  top: -4vh;
  left: -5vh;
  margin: 3px;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

.stw-letter-audio-hidden {
  height: 4vh;
  width: 4vh;
  margin: 3px;
  position: relative;
  background: transparent;
  top: -2vh;
  left: 2vh;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

/** Make The... support audio buttons **/
.mt-letter-audio {
  height: 4vh;
  width: 4vh;
  position: relative;
  top: -4vh;
  left: -9vh;
  z-index: 1;
  margin: 3px;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
}

.mt-letter-audio-hidden {
  height: 4vh;
  width: 4vh;
  margin: 3px;
  position: relative;
  background: transparent;
  top: -2vh;
  left: -3vh;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

/* Fill in the blank specific size */
.target-letter-audio.fib,
.target-letter-audio-hidden.fib {
  height: 50%;
  left: -25%;
  top: -25%;
}

.target-letter-audio.stw,
.target-letter-audio-hidden.stw {
  height: 55%;
  left: -30%;
  top: -50%;
}

.target-letter-audio {
  width: 70%;
  height: 60%;
  position: absolute;
  left: -35%;
  top: -30%;
  z-index: 1;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

.target-letter-audio-hover {
  width: 70%;
  padding-top: 42%;
  position: relative;
  left: -33%;
  top: -10%;
  z-index: 1;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}


.target-letter-audio-hidden {
  width: 70%;
  height: 60%;
  position: absolute;
  left: -35%;
  top: -30%;
  z-index: 1;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.ftp-letter-audio {
  width: 20%;
  padding-top: 17%;
  position: relative;
  top: -15%;
  left: 0%;
  margin: 3px;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
}

.ftp-letter-audio-hidden {
  width: 20%;
  padding-top: 17%;
  position: relative;
  top: -15%;
  left: 0%;
  margin: 3px;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.slash {
  position: relative;
  width: 8%;
  display: inline-block;
  background: transparent;
  margin: 1%;
  background-image: url(../assets/images/incorrectSlash.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 31%;
  background-position-x: 51%;
  background-position-y: 51%;}

.slash-target {
  width: 100%;
  display: inline-block;
  background: transparent;
  margin: 1%;
  background-image: url(../assets/images/incorrectSlash.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 31%;
  background-position-x: 51%;
  background-position-y: 51%;}

.target-block {
  height: 90%;
  width: 8%;
  display: inline-block;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin: 1%;
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 2vh;}

  /* .diagnostic-target-block {
    height: 100%;
    width: 8%;
    display: inline-block;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    margin: 1%;
    text-align: center;
    vertical-align: middle;
    line-height: 250%;
    border-radius: 2vh;} */

.target-block-prepost {
  height: 80%;
  width: 7%;
  display: inline-block;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin: 1%;
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 2vh;}

.diagnostic-target-block {
  height: 55%;
  width: 6.5%;
  display: inline-block;
  position: relative;
  background-color: #00B4A7;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin: 1%;
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 2vh;}

.ctw-target-block {
  height: 100%;
  width: 8%;
  display: inline-block;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin: 1%;
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 20px;
}

.ctw-target-block:not(.hover) {
  border: 6px solid #FFFFFF !important;
}


.target-block-hidden {
  height: 10vh;
  width: 10vh;
  display: inline-block;
  background: #FFFFFF;
  margin: 1vh;
  text-align: center;
  vertical-align: middle;
  line-height: 10vh;
  border-radius: 2vh;
  margin-top: 1.5vh;
  font-size: 5vh; }

.first-target-block-left:first-of-type {
  margin-left: -11vh; }

/* Added margin to prevent silent-e shifting during Fill in the blank tasks */
.first-target-block-left-fill:first-of-type {
  margin-left: 9.5%;
}

.first-target-block-left-fill.prepost-fill:first-of-type {
  margin-left: 10%;
}

.first-target-block-left-fill.unit-fill:first-of-type {
  margin-left: 11%;
}

.keep-target-from-moving-left:first-of-type {
  margin-left: 13vh; }

.big-div-letter-audio {
  width: 17%;
  padding-top: 17%;
  position: relative;
  top: -8%;
  left: 8%;
  margin: 3px;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

.big-div-letter-audio-hidden {
  width: 17%;
  padding-top: 17%;
  position: relative;
  top: -8%;
  left: 8%;
  margin: 3px;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.letter-audio {
  height: 0%;
  width: 20%;
  position: relative;
  bottom: 57%;
  left: 11%;
  padding-bottom: 18%;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 1;}

.letter-audio-hidden {
  height: 0%;
  width: 20%;
  position: relative;
  bottom: 57%;
  left: 11%;
  padding-bottom: 18%;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

  /* This is kind of hacky but is the only way I could get this to work. The idea is that when the response block class is hovered, the response block should get a border added.
  The border should also be applied to the silent-e tile if it's present.  */
  .intervention-target-block.hover
  {
    border: 4px solid; padding-bottom: 33%;
  }

  .hover{
    border-width: 6px !important;
    border-style: solid !important;
    padding: 0 !important;
  }

  .make-the-response-block.hover {
    border: 4px solid;
  }
  .find-the-phrase-response-block.hover {border: 6px solid; padding: 0;}
  .syllable-breaks-response-block.hover {border: 6px solid; padding: 0; }
  /* .button-div:hover .response-block{
    border: 6px solid;
    padding-bottom: 33%;
    line-height: 275%;
  }

  .button-div:hover .silent-e{
    border: 6px solid;
    padding-bottom: 10%;
    line-height: 70%;
  } */

  /* .silent-e.hover + .response-block {border: 4px solid;} /* This won't work since the .response-block class comes before the silent-e. Since we are using css, the styles
  can only be applied in a forward or cascading direction, not to previous classes */

  .audio-support-top-left-corner {
    height: 4vh;
    width: 4vh;
    position: relative;
    top: -3vh;
    left: 2vh;
    margin: 3px;
    background-image: url(../assets/images/audioBttnSupport.svg);
    background-repeat: no-repeat;
    display: inline-block;
    background-size: contain;
    background-color: transparent;
    outline: none;
    border: none;}

  .audio-support-top-left-corner-hidden {
    height: 4vh;
    width: 4vh;
    margin: 3px;
    position: relative;
    background: transparent;
    top: -3vh;
    left: 2vh;
    border: none;
    outline: none;
    cursor: default; }

  .remove-letter {
    width: 45%;
    padding-top: 45%;
    position: relative;
    left: 28%;
    margin-top: -20%;
    box-sizing: border-box;
    background-image: url(../assets/images/buttons/eraseBttnShapeTriangle.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #185CAC;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 10px;
    border: 4px white;
    outline: none;
    opacity: 1;}

  .remove-letter-disabled {
    width: 45%;
    padding-top: 45%;
    position: relative;
    left: 28%;
    margin-top: -20%;
    box-sizing: border-box;
    background-image: url(../assets/images/buttons/eraseBttnShapeTriangle.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #185CAC;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 10px;
    border: 4px white;
    outline: none;
    opacity: 0.4; }

  .remove-letter-disabled-hover {
    width: 45%;
    padding-top: 45%;
    position: relative;
    left: 28%;
    margin-top: -20%;
    box-sizing: border-box;
    background-image: url(../assets/images/buttons/eraseBttnShapeTriangle.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #185CAC;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 10px;
    border: 4px white;
    outline: none;
    opacity: 0.4; }


  .syllable-breaks-response-block {
    width: 100%;
    background: #FFFFFF;
    border: 6px #FFFFFF;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    vertical-align: middle;
    line-height: 250%;
    border-radius: 13px;
    outline: none; }

.find-the-phrase-response-block {
  width: 80%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 13px;
  outline: none;
}

.find-the-phrase-button-div {
  width: 100%;
  height: 15%;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: flex-end;}

.audio-support-top-left-corner {
  width: 4%;
  padding-top: 4%;
  position: relative;
  top: -65%;
  left: 2%;
  margin: 3px;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

.audio-support-top-left-corner-hidden {
  width: 4%;
  padding-top: 4%;
  position: relative;
  top: -65%;
  left: 2%;
  background: transparent;
  border: none;
  outline: none;
  cursor: default; }


.button-div {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 15px;
  margin-right: 15px;}

.stw-target-div {
  width: 10%;
  height: 100%;
  display: inline-block;
  margin-left: 2%;
  margin-right: 2%;
}

.stw-target-block {
  height: 100%;
  width: 100%;
  position: relative;
  top: -35%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  outline: none; }

.stw-target-block.hover {
  height: 100%;
  width: 100%;
  position: relative;
  top: -35%;
  background: #FFFFFF;
  border: 6px;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  outline: none;
}

.diagnostic-button-div {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: 15px;}

.picture-button-div {
  width: 50%;
  height: 82.5%;
  display: flex;
  justify-content: center;
  align-items: flex-end;}

.picture-button-div-intervention {
  width: 50%;
  height: 82.5%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;}

.big-button-div {
  display: flex;
  height: 100%;
  width: 24%;
  margin-right: 2%;
  margin-left: 2%;
  justify-content: flex-start;
  align-items: flex-start; }

  /* Find the Syllable screen */

  .syllable-the-blank-container {
    width: 90%;

    height: 63%;
    margin: auto;
    margin-top: 1vh;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

  .syllable-response-row{
    width: 70%;
    height: 25%;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
  }

  .find-number-syllables-target-area {
    width: 95%;
    height: 32%;
    margin: auto;
    position: relative;
    top: 5vh;
    margin-bottom: 5vh;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #4A4A4A;
    text-align: center;
  }

  .find-number-syllables-target-text {
    height: 100px;
    width: 60%;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
    border-radius: 9px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: auto;
  }

  .find-number-syllables-target-text-instructional {
    height: 100px;
    width: 60%;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: auto;
  }

  .find-number-syllables-target-area-instructional {
    width: 95%;
    height: 32%;
    margin: auto;
    position: relative;
    top: 6%;
    margin-bottom: 5%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #4A4A4A;
    text-align: center;
  }

  .syllable-container {
    display: inline-block;
    height: 100%;
  }

  .syllable-container-initial {
    display: inline-block;
    position: relative;
    top:10%;
  }

  .masked-syllable-target-word {
    position: relative;
    top: 10%;
  }

  .syllable-text {
    position: relative;
    top: -20%;
    letter-spacing: 1px;
  }

  .syllable-text.underline {
    border-bottom: 2px solid;
  }

  .syllable-1.colored {
    color: #00B4A7;
    border-color: #00B4A7;
  }
  .syllable-2.colored {
    color: #AB7BBA;
    border-color: #AB7BBA;
  }
  .syllable-3.colored {
    color: #F6A623;
    border-color: #F6A623;
  }
  .syllable-4.colored {
    color: #75A5D5;
    border-color: #75A5D5;
  }

  .syllable-audio-btn {
    height: 30px;
    width: 30px;
    background-image: url(../assets/images/audioBttnSupport.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    outline: none;
    border: none;
  }

  .syllable-audio-btn-hidden {
    height: 30px;
    width: 30px;
    background: transparent;
    border: none;
    outline: none;
    cursor: default;
  }

  .word-to-syllable {
    width: 95%;
    height: 20%;
    margin: auto;
    position: relative;
    top: 5vh;
    margin-bottom: 10vh;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #4A4A4A;
    text-align: center; }

  .syllable-response-correct {
    height: 100%;
    width: 100%;
    outline: none;
    padding: 6px;
    background-size: contain;
    box-sizing: border-box;
    border: 6px rgba(129,190,67,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    font-size: 90px;
    line-height: 100%;
    font-weight: bold;
    color: white;
    border-radius: 18px;
    background-color: #81BE43; }

  .syllable-response-correct-grayed {
    height: 100%;
    width: 100%;
    outline: none;
    padding: 6px;
    background-size: contain;
    box-sizing: border-box;
    border: 6px rgba(129,190,67,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    font-size: 90px;
    line-height: 100%;
    font-weight: bold;
    color: white;
    border-radius: 18px;
    background-color: #81BE43;
    opacity: 0.4; }

  .syllable-response-incorrect {
    height: 100%;
    width: 100%;
    outline: none;
    padding: 6px;
    background-size: contain;
    box-sizing: border-box;
    border: 6px rgba(213,45,66,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    font-size: 100px;
    line-height: 100%;
    font-weight: bold;
    color: white;
    border-radius: 18px;
    background-color: #D52D42; }

  .syllable-response-incorrect-grayed {
    height: 100%;
    width: 100%;
    outline: none;
    padding: 6px;
    background-size: contain;
    box-sizing: border-box;
    border: 6px rgba(213,45,66,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    font-size: 100px;
    line-height: 100%;
    font-weight: bold;
    color: white;
    border-radius: 18px;
    background-color: #D52D42;
    opacity: 0.4; }

  .syllable-response-correct.hover {
      border: 6px solid;
      padding: 0px;
      box-sizing: border-box; }

  .syllable-response-incorrect.hover {
    border: 6px solid;
    padding: 0px;
    box-sizing: border-box; }

  .syllable-word {
    height: 100px;
    width: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
    border-radius: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: auto;
    font-size: 40px; }

/* Verify the blank screen */

.verify-the-blank-container {
  width: 90%;

  height: 63%;
  margin: auto;
  margin-top: 1vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

.word-to-verify {
  width: 95%;
  height: 25%;
  margin: auto;
  position: relative;
  top: 10%;
  margin-bottom: 10%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center; }

.target-area-ftb-unit {
  width: 95%;
  height: 34%;
  margin: auto;
  position: relative;
  display: block;
  border: 1px solid green;
  margin-bottom: 2%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;}


.verify-response-correct {
  height: 130%;
  width: 13%;
  outline: none;
  padding: 6px;
  box-sizing: border-box;
  border: 6px rgba(129,190,67,1);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px;
  background: url(../assets/images/buttons/respBttnShapeYes.svg) no-repeat center center;
  background-color: #81BE43;
  background-size: 80%; }

.verify-response-correct-grayed {
  height: 130%;
  width: 13%;
  outline: none;
  padding: 6px;
  box-sizing: border-box;
  border: 6px rgba(129,190,67,1);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px;
  background: url(../assets/images/buttons/respBttnShapeYes.svg) no-repeat center center;
  background-color: #81BE43;background-size: 80%;
  opacity: 0.4; }

.verify-response-incorrect {
  height: 130%;
  width: 13%;
  outline: none;
  padding: 6px;
  box-sizing: border-box;
  border: 6px rgba(213,45,66,1);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px;
  background: url(../assets/images/buttons/respBttnShapeNo.svg) no-repeat center center;
  background-color: #D52D42;
  background-size: 80%;  }

.verify-response-incorrect-grayed {
  height: 130%;
  width: 13%;
  outline: none;
  padding: 6px;
  box-sizing: border-box;
  border: 6px rgba(213,45,66,1);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px;
  background: url(../assets/images/buttons/respBttnShapeNo.svg) no-repeat center center;
  background-color: #D52D42;
  background-size: 80%;
  opacity: 0.4; }

.next-trial-base {
  height: 80%;
  width: 9%;
  outline: 0px;
  margin-right: 5%;
  margin-left: 5%;
  vertical-align: top;
  padding:  6px;
  box-sizing: border-box;
  border-radius: 18px;
  background: none;
  box-shadow: none;
  border: none;
}

.next-trial {
  border: 6px rgba(25,93,172,1);
  background: url(../assets/images/buttons/goBttnShapeTriangle.svg) no-repeat center center;
  background-color: #195DAC;
  background-size: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
}

.next-trial-grayed {
  outline: none;
  opacity: 0.4; }

.next-trial-hidden {
  background: none;
  box-shadow: none;
  border: none;
}

.toggle-next-on {
  /*animation: blink 1s ease infinite;*/
  animation: pulse 1s ease-in-out;
}

.verify-word {
  height: 100px;
  width: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
  border-radius: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: auto; }


.find-the-syllables-speaker-adjust {
  margin-top: 1%;
}

.find-the-word-syllables-target-text {
  height: 100px;
  width: 50%;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  border-radius: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: auto;
}

.find-the-word-syllables-target-area {
  width: 95%;
  height: 20%;
  margin: auto;
  position: relative;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center; }

.find-the-word-syllables-target-area-no-border {
  width: 95%;
  height: 0;
  margin: auto;
  position: relative;
  text-align: center; }

.find-the-phrase-target-area-no-border {
  width: 95%;
  height: 0;
  margin: auto;
  position: relative;
  text-align: center; }


.find-the-word-family-text-color {
  color:  #00B4A7;
  font-weight: bold;
}

  /* Find the Rhyme screen */

.find-the-rhyme-container {
  width: 90%;

  height: 63%;
  margin: auto;
  margin-top: 1vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }


.rhyme-response-block {
  height: 80%;
  width: 100%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 20px;
  margin-right: 3%;
  padding: 6px;
  outline: none; }

.rhyme-instructional-response-block {
  width: 70%;
  position: relative;
  top: -50%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 200%;
  border-radius: 13px;
  outline: none; }

.rhyme-instructional-response-block-grayed {
  width: 70%;
  position: relative;
  top: -50%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 13px;
  padding: 6px;
  outline: none;
  opacity: 0.4; }

.rhyme-letter-audio {
  width: 17%;
  padding-top: 17%;
  position: relative;
  top: -60%;
  left: 10%;
  margin: 3px;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 1;}

.rhyme-letter-audio-hidden {
  width: 17%;
  padding-top: 17%;
  position: relative;
  top: -60%;
  left: 10%;
  margin: 3px;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.rhyme-response-block-grayed {
  height: 80%;
  width: 100%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 20px;
  margin-right: 3%;
  padding: 6px;
  outline: none;
  opacity: 0.4; }

.rhyme-instructional-response-block.hover {border: 6px solid; padding: 0px;}

.find-the-word-syllables-response-row{
    width: 100%;
    height: 20%;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.make-the-response {
  display: inline-block;}

.make-the-response-row {
  width: 90%;
  height: 15%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center; }

.make-the-phrase-big-button-div {
  display: inline-block;
  height: 100%;
  width: 20%;
  margin-right: 2%;
  margin-left: 2%; }

.make-the-phrase-big-div-letter-audio {
  width: 17%;
  padding-top: 17%;
  position: relative;
  top: -25%;
  left: 8%;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

.make-the-phrase-big-div-letter-audio-hidden {
  width: 17%;
  padding-top: 17%;
  position: relative;
  top: -15%;
  left: 10%;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.make-the-target-row{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  top: 20%;
  margin-left: 4%;
}

.make-the-target-text {
  width: 100%;
  position: relative;
  top: -35%;
  background: transparent;
  border: 6px transparent;
  vertical-align: middle;
}

.make-the-response-target-block {
  height: 90%;
  width: 15%;
  display: inline-block;
  position: relative;
  top: -57%;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin: 1% 1.5% 1% 1%;
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 10px; }



.make-the-phrase-response-block {
  height: 100%;
  width: 80%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 20px;
  outline: none; }

.make-the-response-block-grayed {
  height: 100%;
  width: 70%;
  background: #FFFFFF;
  border: 6px #FFFFFF;
  padding: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 250%;
  border-radius: 20px;
  font-size: 30px;
  outline: none;
  opacity: 0.4; }

.make-the-target-letter-audio {
  width: 30%;
  padding-top: 30%;
  position: relative;
  left: -50%;
  top: -20%;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;}

  .hover .make-the-target-letter-audio {
    left: -55%;
    width: 33%;
    top: -34%;
    padding-top: 33%;
  }


.make-the-target-letter-audio-hidden {
  width: 30%;
  padding-top: 30%;
  position: relative;
  left: -45%;
  background: transparent;
  border: none;
  outline: none;
  cursor: default;
  z-index: -1;
}

.make-the-remove-letter,
.ctm-remove-suffix-prefix {
  width: 25%;
  padding-top: 25%;
  position: absolute;
  top: 110%;
  left: 40%;
  box-sizing: border-box;
  background-image: url(../assets/images/buttons/eraseBttnShapeTriangle.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
  background-color: #185CAC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  border: 4px white;
  outline: none;
  opacity: 1;}

.make-the-remove-letter-disabled {
  width: 25%;
  padding-top: 25%;
  position: absolute;
  top: 110%;
  left: 40%;
  box-sizing: border-box;
  background-image: url(../assets/images/buttons/eraseBttnShapeTriangle.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
  background-color: #185CAC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
  border: 4px white;
  outline: none;
  opacity: 0.4; }

.make-the-remove-letter-disabled-hover {
  width: 28%;
  padding-top: 27%;
  position: absolute;
  top: 121%;
  left: 40%;
  box-sizing: border-box;
  background-image: url(../assets/images/buttons/eraseBttnShapeTriangle.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
  background-color: #185CAC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
  border: 4px white;
  outline: none;
  opacity: 0.4; }

  /* Find the Picture screen */

.find-the-picture-container,
.find-the-syllables-container {
  width: 90%;
  height: 63%;
  margin: auto;
  margin-top: 1vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

.word-to {
  width: 95%;
  height: 25%;
  margin: auto;
  position: relative;
  top: 10%;
  margin-bottom: 5%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center; }

.word {
  width: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 300%;
  border-radius: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: auto;}

.word-masked {
  width: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 200%;
  border-radius: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: auto;}

.picture-response-block {
  height: 100%;
  width: 65%;
  border: 6px transparent;
  padding: 6px;
  padding-bottom: 45%;
  box-sizing: border-box;
  horiz-align: center;
  background-repeat: no-repeat;
  background-size: 100%;}

.picture-response-block-grayed {
  height: 100%;
  width: 65%;
  border: 6px transparent;
  padding: 6px;
  padding-bottom: 45%;
  box-sizing: border-box;
  horiz-align: center;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.4; }

.picture-response-block.hover {border: 4px solid; padding-bottom: 0%;}

.next-button {
  height: 77%;
  width: 8%;
  outline: 0px;
  margin-right: 5%;
  margin-left: 3%;
  vertical-align: top;
  padding:  6px;
  background-size: contain;
  box-sizing: border-box;
  border: 6px rgba(25,93,172,1);
  background: url(../assets/images/buttons/goBttnShapeTriangle.svg) no-repeat center center;
  background-color: #195DAC;
  background-size: 80%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px; }

.next-button-grayed {
  height: 77%;
  width: 8%;
  outline: 0px;
  margin-right: 5%;
  margin-left: 3%;
  vertical-align: top;
  padding:  6px;
  background-size: contain;
  box-sizing: border-box;
  border: 6px rgba(25,93,172,1);
  background: url(../assets/images/buttons/goBttnShapeTriangle.svg) no-repeat center center;
  background-color: #195DAC;
  background-size: 80%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px;
  opacity: 0.4; }

.next-button-hidden {
  background: none;
  box-shadow: none; }

.again-button {
  height: 25%;
  width: 3%;
  outline: 0px;
  margin-right: 3%;
  margin-left: 3%;
  vertical-align: top;
  padding:  2px;
  background-size: contain;
  border: 2px rgba(25,93,172,1);
  background: url(../assets/images/buttons/goBttnShapeTriangle.svg) no-repeat center center;
  background-color: #195DAC;
  background-size: 80%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 6px; }

.again-button-grayed {
  height: 25%;
  width: 3%;
  outline: 0px;
  margin-right: 3%;
  margin-left: 3%;
  vertical-align: top;
  padding:  2px;
  background-size: contain;
  box-sizing: border-box;
  border: 2px rgba(25,93,172,1);
  background: url(../assets/images/buttons/goBttnShapeTriangle.svg) no-repeat center center;
  background-color: #195DAC;
  background-size: 28%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 6px;
  opacity: 0.4; }

.again-button-hidden {
  background: none;
  box-shadow: none; }

.change-the-blank-container {
  width: 90%;

  height: 63%;
  margin: auto;
  margin-top: 1vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

  /* Your Time Is Up Screen */

.timeup-dialog {
  background-image: url(../assets/images/dialogs/dialog-yourTimeIsUp.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  position: relative;
  width: 46%;
  height: 41%;
  z-index: 1101;
  margin-top: 21%;
}

.congratulations-dialog {
  background-image: url(../assets/images/dialogs/dialog-congratulations.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  position: relative;
  width: 46%;
  height: 41%;
  z-index: 1101;
  margin-top: 14%;
}

/* Please Wait Dialog */
.please-wait-dialog {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  width: 46%;
  height: 41%;
  top: 25%;
  left: 27%;
  z-index: 1101;
  margin-top: 5%;
}

.full-dialog-background {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  opacity: 0.8;
  position: absolute;
}

.caps-warning-opacity-layer {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  opacity: 0.3;
  position: absolute;
  background-color: #FFFFFF;
}

.caps-warning-dialog-background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 90%;
  z-index: 1101;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caps-warning-dialog-text {
  width: 100%;
  position: relative;
  margin-top: 2vh;
  text-align: center;

  font-family: Helvetica;
  font-size: 32px;
  color: #4A4A4A;
  letter-spacing: 7.26px;
  text-align: center;
}

.caps-warning-dialog-window {
  background-color: #aeaeaf;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  line-height: 200%;
  width: 58%;
  border: 7px solid #4A4A4A;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.30);
  border-radius: 40px;
}

.caps-warning-dialog-cancel-button {
  border: none;
  background: url(../assets/images/buttons/respBttnTryAgain.svg) no-repeat center center;
  width: 98px;
  height: 98px;
  scale: 0.9;
}

.caps-warning-dialog-button-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1em;
  margin-bottom: 1em;
}

.caps-warning-dialog-ok-button {
  width: 102px;
  height: 98px;
  scale: 0.9;
  border: none;
  background: url(../assets/images/buttons/respBttnShapeYes.svg) no-repeat center center;
}

.caps-warning-current-response-text {
  width: 100%;
  font-family: Helvetica;
  font-weight: bold;
  color: #dcd66f;
  letter-spacing: 7.26px;
  position: relative;
  font-size: 32px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}

/* Change the blank screen */

@media (max-height: 751px) {

  .change-the-response-block {
    height: 10vh;
    width: 10vh;
    background: #FFFFFF;
    border: 4px #FFFFFF;
    padding: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    vertical-align: middle;
    line-height: 10vh;
    border-radius: 2vh;
    font-size: 4vh;
    outline: none;
  }

  .change-the-response-block-grayed {
    height: 10vh;
    width: 10vh;
    background: #FFFFFF;
    border: 4px #FFFFFF;
    padding: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    vertical-align: middle;
    line-height: 10vh;
    border-radius: 2vh;
    font-size: 4vh;
    outline: none;
    opacity: 0.4;
  }

  .silent-e-change {
    height: 4vh;
    width: 4vh;
    position: relative;
    left: -2.1vw;
    top: -2vh;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    margin: 5px;
    text-align: center;
    vertical-align: middle;
    line-height: 3vh;
    border-radius: 1vh;
    font-size: 20px;
    border: none;
    padding: 4px;
    outline: none; }

  .silent-e-change-grayed {
    height: 4vh;
    width: 4vh;
    position: relative;
    left: -2.1vw;
    top: -2vh;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    margin: 5px;
    text-align: center;
    vertical-align: middle;
    line-height: 3vh;
    border-radius: 1vh;
    font-size: 20px;
    border: none;
    padding: 4px;
    outline: none;
    opacity: 0.4; }

  .silent-e-change-hidden {
    height: 4vh;
    width: 4vh;
    position: relative;
    margin: 5px;
    background: transparent;
    left: -10px;
    top: -10px;
    border: none;
    outline: none;
    cursor: default; }

  /* This is kind of hacky but is the only way I could get this to work. The idea is that when the response block class is hovered, the response block should get a border added.
    The border should also be applied to the silent-e tile if it's present.  */
  .change-the-response-block.hover {border: 4px solid; padding: 0px;}
  .change-the-response-block.hover + .silent-e-change {border: 4px solid; padding: 0px;}
  .silent-e-change.hover {border: 4px solid; padding: 0px;}
  .silent-e-change.hover + .change-the-response-block {border: 4px solid; padding: 0px;} /* Doesn't work */
}

@media (min-height: 751px) {

  .change-the-response-block {
    height: 10vh;
    width: 10vh;
    background: #FFFFFF;
    border: 6px #FFFFFF;
    padding: 6px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    vertical-align: middle;
    line-height: 10vh;
    border-radius: 2vh;
    font-size: 4vh;
    outline: none;
  }

  .change-the-response-block-grayed {
    height: 10vh;
    width: 10vh;
    background: #FFFFFF;
    border: 6px #FFFFFF;
    padding: 6px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    vertical-align: middle;
    line-height: 10vh;
    border-radius: 2vh;
    font-size: 4vh;
    outline: none;
    opacity: 0.4;
  }

  .silent-e-change {
    height: 4vh;
    width: 4vh;
    position: relative;
    left: -1.4vw;
    top: -2vh;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    margin: 5px;
    text-align: center;
    vertical-align: middle;
    line-height: 3vh;
    border-radius: 1vh;
    font-size: 25px;
    border: none;
    padding: 6px;
    outline: none; }

  .silent-e-change-grayed {
    height: 4vh;
    width: 4vh;
    position: relative;
    left: -1.4vw;
    top: -2vh;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    margin: 5px;
    text-align: center;
    vertical-align: middle;
    line-height: 3vh;
    border-radius: 1vh;
    font-size: 25px;
    border: none;
    padding: 6px;
    outline: none;
    opacity: 0.4; }

  .silent-e-change-hidden {
    height: 4vh;
    width: 4vh;
    position: relative;
    margin: 5px;
    background: transparent;
    left: -10px;
    top: -10px;
    border: none;
    outline: none;
    cursor: default; }

    /* This is kind of hacky but is the only way I could get this to work. The idea is that when the response block class is hovered, the response block should get a border added.
    The border should also be applied to the silent-e tile if it's present.  */
    .change-the-response-block.hover {border: 6px solid; padding: 0px;}
    .change-the-response-block.hover + .silent-e-change {border: 6px solid; padding: 0px;}
    .silent-e-change.hover {border: 6px solid; padding: 0px;}
    .silent-e-change.hover + .change-the-response-block {border: 6px solid; padding: 0px;} /* Doesn't work */
}

@media (max-height: 766px) {
  .ctm-areas {
    padding-top: 5px !important;
  }

  .ctm-prefix-tile,
  .ctm-suffix-tile,
  .ctm-root-word-area {
    height: 40px !important;
  }

  .ctm-dictionary-top,
  .ctm-dictionary-bottom {
    top: 70px !important;
  }
}

.videogular-container {
  max-height: 100%;
  max-width: 100%;
  height: 80%;
  width: 80%;
  margin: auto;
  overflow: hidden;
  z-index: 1201;
  position: absolute;
  top: 10%;
  left: 10%;
  text-align: center;
}

.video-background {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1200;
  background-color: #1D5551;
  opacity: 0.9;
  position: absolute;
}

.video-container {
  height: 100%;
  width: 100%;
}

video, fillContainerVideo {
  height: 100%;
  width: 100%;
}

video::-webkit-media-controls {
  display: none !important;
  opacity: 0;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.video-background::-webkit-media-controls-panel,
.video-background::-webkit-media-controls-start-playback-button {
    display: none !important;
}


.logout-confirm-text{
  font-family: Helvetica;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  position: relative;
  margin: 10%;
  text-align: center;
}

.logout-confirm-dialog-background{
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1100;
  opacity: 0.8;
  position: absolute;
  background-image: linear-gradient(180deg, #A3D7DB 2%, #50AEA7 87%);
}

.logout-confirm-dialog-container {
  max-height: 100%;
  max-width: 100%;
  height: 40%;
  width: 40%;
  overflow: hidden;
  z-index: 1101;
  position: absolute;
  top: 25%;
  left: 30%;
  background: rgb(255, 255, 255, 0.90);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.try-again-dialog-text,
.focus-dialog-text {
  width: 100%;
  font-family: Helvetica;
  font-weight: bold;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  position: relative;
  margin-top: 2vh;
  text-align: center;
}

.try-again-dialog-subtext {
  font-family: Helvetica;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  position: relative;
  margin: 10vh;
  margin-top: 2vh;
  text-align: center;
}


.try-again-dialog-btn, .try-again-dialog-btn:hover, .focus-dialog-btn {
    height: 85%;
    width: 40%;
    border: 3px solid #146448;
    border-radius: 12px;
    background-color: transparent;
    outline: none;
    z-index: 1002;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.try-again-dialog-btn:disabled {
  opacity: 0.3;
}

.try-again-text,
.focus-text {
  width: 77%;
  font-family: Helvetica;
  font-weight: bold;
  color: #146448;
  letter-spacing: 3.93px;
  line-height: 150%;
  display: inline-block; }

.try-again-icon,
.take-me-back-icon{
  height: 25px;
  width: 25px;
  display: inline-block;
  background-image: url(../assets/images/buttons/tryAgainBttnShape.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

}

.try-later-dialog-btn, .try-later-dialog-btn:hover {
  height: 85%;
  width: 40%;
  border: 3px solid #185CAC;
  border-radius: 12px;
  background-color: transparent;
  outline: none;
  z-index: 1002;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.try-later-dialog-btn:disabled {
  opacity: 0.3;
}

.try-later-icon{
  height: 25px;
  width: 25px;
  display: inline-block;
  background-image: url(../assets/images/buttons/tryLaterBttnShape.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.try-later-text {
  width: 77%;
  font-family: Helvetica;
  font-weight: bold;
  vertical-align: center;
  color: #185CAC;
  letter-spacing: 3.93px;
  line-height: 150%;
  display: inline-block;}

.focus-dialog-container{
  max-height: 100%;
  max-width: 100%;
  height: 40%;
  width: 40%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 25%;
  left: 30%;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 0 50vmax rgba(119, 119, 119, 0.5);
  border: 2px solid #4A4A4A  ;
}

.focus-dialog-subtext{
  font-family: Helvetica;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  position: relative;
  margin-right: 5vh;
  margin-left: 5vh;
  margin-top: 1vh;
  text-align: center;
}
.dialog-background {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  background-image: linear-gradient(-180deg, #A3D7DB 2%, #50AEA7 87%);
  opacity: 0.3;
  position: absolute;
}

.try-again-dialog-background {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  opacity: 0.8;
  position: absolute;
}

.dialog-container {
  max-height: 100%;
  max-width: 100%;
  height: 70%;
  width: 70%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 10%;
  left: 15%;
  background-image: linear-gradient(180deg, #A3D7DB 2%, #50AEA7 86%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 2px solid black;
}



.try-again-dialog-container {
  max-height: 100%;
  max-width: 100%;
  height: 40%;
  width: 40%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 25%;
  left: 30%;
  background: rgb(255, 255, 255, 0.90);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.save-data-dialog-top-container {
  height: 80%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid black;
  overflow: hidden;
}

.loading-assets-dialog-top-container {
  height: 75%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid black;
  overflow: hidden;
}

.try-again-dialog-top-container,
.focus-dialog-top-container {
  height: 75%;
  width: 100%;
  overflow: hidden;
}

.save-data-dialog-bottom-container {
  height: 25%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-around;
}

.loading-assets-dialog-bottom-container {
  height: 25%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-around;
}

.try-again-dialog-bottom-container,
.focus-dialog-bottom-container {
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.loading-assets-dialog-text {
  font-family: Helvetica;
  font-size: 30px;
  font-size: 5vh;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  position: relative;
  margin-left: 2vw;
  margin-top: 2vh;
  text-align: left;
}

.save-data-dialog-text {
    font-family: Helvetica;
    font-size: 30px;
    font-size: 5vh;
    font-weight: normal;
    color: #4A4A4A;
    letter-spacing: 4.91px;
    position: relative;
    margin-left: 2vw;
    margin-top: 2vh;
    text-align: left;
}

.save-data-dialog-btn, .save-data-dialog-btn:hover {
    border: 3px solid #50AEA7;
    border-radius: 12px;
    background-color: transparent;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1002;
    margin: auto;
}

.save-data-dialog-btn:disabled {
  opacity: 0.3;
}

.loading-assets-dialog-btn, .loading-assets-dialog-btn:hover {
  border: 3px solid #50AEA7;
  border-radius: 12px;
  background-color: transparent;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1002;
  margin: auto;
}

.loading-assets-dialog-btn:disabled {
  opacity: 0.3;
}

.avatar-icon-img {
  width: 25%;
  margin: auto;
  margin-top: 5%;
  display: block;
}

.passages-container {
  width: 90%;
  height: 59%;
  margin: auto;
  margin-top: 1vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.exit-passages-dialog {
  width: 40px;
  height: 40px;
  background-image: url(../assets/images/buttons/closeBttn.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  float: left;
}

.passages-title-audio {
  width: 6%;
  padding-top: 6%;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
}

.passages-line-audio {
  padding-top: 4%;
  width: 4%;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
}

.passages-line{
  line-height: 250%;
  letter-spacing: 2px;
  color: #4A4A4A;
}

.passages-line-highlight {
  color: #0D75F0;
}

.passage-dialog-container {
  max-height: 100%;
  max-width: 100%;
  height: 79%;
  width: 90%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 15%;
  left: 5%;
  background-image: linear-gradient(180deg, #A3D7DB 2%, #50AEA7 86%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 0px solid black;
}

.passages-target-area {
  width: 95%;
  height: 20%;
  margin: auto;
  position: relative;
  top: 8%;
  margin-bottom: 5%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;
}



.dialog-scrollable-sentence-container {
  height: 70%;
  width: 90%;
  position: absolute;
  top: 25%;
  left: 5%;
  overflow: auto;
}

.passages-done-btn {
  width: 12%;
  padding-bottom: 10%;
  margin: 2%;
  outline: none;
  background-size: 70%;
  box-sizing: border-box;
  border: 6px rgba(129,190,67,1);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px;
  background: url(../assets/images/buttons/respBttnShapeYes.svg) no-repeat center center;
  background-color: #81BE43;
}

.passages-done-btn-grayed {
  width: 12%;
  padding-bottom: 10%;
  margin: 2%;
  outline: none;
  background-size: 70%;
  box-sizing: border-box;
  border: 6px rgba(129,190,67,1);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 18px;
  background: url(../assets/images/buttons/respBttnShapeYes.svg) no-repeat center center;
  background-color: #81BE43;
  opacity: 0.4;
}

.done-btn-div {
  text-align: center;
}


#passage-scroll-1::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

#passage-scroll-1::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#passage-scroll-1::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(24,71,96,1);
}

#passage-scroll-2::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

#passage-scroll-2::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#passage-scroll-2::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(72,15,42,1);
}

#passage-scroll-3::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

#passage-scroll-3::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#passage-scroll-3::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(28,50,28,1);
}

#passage-scroll-4::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

#passage-scroll-4::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#passage-scroll-4::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(73,19,70,1);
}

#passage-scroll-5::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

#passage-scroll-5::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#passage-scroll-5::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(71,25,25,1);
}

#passage-scroll-6::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

#passage-scroll-6::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#passage-scroll-6::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(63,96,45,1);
}

#passage-scroll-7::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

#passage-scroll-7::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

#passage-scroll-7::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(40,76,73,1);
}

/* Animations */

  /* Example of how to create a class with animation that we defined using keyframes
  #box {
    -webkit-animation: NAME-YOUR-ANIMATION 5s infinite; /* Safari 4+
    -moz-animation:    NAME-YOUR-ANIMATION 5s infinite; /* Fx 5+
    -o-animation:      NAME-YOUR-ANIMATION 5s infinite; /* Opera 12+
    animation:         NAME-YOUR-ANIMATION 5s infinite; /* IE 10+, Fx 29+
  }*/

  #current-level:hover {
    cursor: pointer;
  }

  #avatar:hover {
    cursor: pointer;
  }

  .firstSelectScreen-Left {
    animation: firstSelectScreen-Left 2s linear;
    -webkit-animation: firstSelectScreen-Left 2s linear;
    animation-fill-mode: forwards;
  }

  .firstSelectScreen-Right {
    animation: firstSelectScreen-Right 2s linear;
    -webkit-animation: firstSelectScreen-Right 2s linear;
    animation-fill-mode: forwards;
  }

  .firstSelectScreen-Up {
    animation: firstSelectScreen-Up 2s linear;
    -webkit-animation: firstSelectScreen-Up 2s linear;
    animation-fill-mode: forwards;
  }

  .firstSelectScreen-Down {
    animation: firstSelectScreen-Down 2s linear;
    -webkit-animation: firstSelectScreen-Down 2s linear;
    animation-fill-mode: forwards;
  }

  @keyframes firstSelectScreen-Left {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(-100%,0);
    }
  }

  @keyframes firstSelectScreen-Right {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(100%,0);
    }
  }

  @keyframes firstSelectScreen-Up {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(0,-100%);
    }
  }

  @keyframes firstSelectScreen-Down {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(0,100%);
    }
  }

  .nextSelectScreen-Left {
    animation: nextSelectScreen-Left 2s linear;
    -webkit-animation: nextSelectScreen-Left 2s linear;
    animation-fill-mode: forwards;
  }

  .nextSelectScreen-Right {
    animation: nextSelectScreen-Right 2s linear;
    -webkit-animation: nextSelectScreen-Right 2s linear;
    animation-fill-mode: forwards;
  }

  .nextSelectScreen-Up {
    animation: nextSelectScreen-Up 2s linear;
    -webkit-animation: nextSelectScreen-Up 2s linear;
    animation-fill-mode: forwards;
  }

  .nextSelectScreen-Down {
    animation: nextSelectScreen-Down 2s linear;
    -webkit-animation: nextSelectScreen-Down 2s linear;
    animation-fill-mode: forwards;

  }
  @keyframes nextSelectScreen-Left {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(-100%,0);
    }
  }

  @keyframes nextSelectScreen-Right {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(100%,0);
    }
  }

  @keyframes nextSelectScreen-Up {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(0,-100%);
    }
  }

  @keyframes nextSelectScreen-Down {
    0% {
      transform: translate(0,0);
    }

    100% {
      transform: translate(0,100%);
    }
  }



  @keyframes backButtonPulse {
    0% {
      transform: scale(1);
    }

    50%{
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes slideOutUp {
    0% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.8;
    }
  }

  .full-fade-in {
    -webkit-animation: fullFadeIn 2s ease-in;
    -moz-animation: fullFadeIn 2s ease-in;
    -o-animation: fullFadeIn 2s ease-in;
    animation: fullFadeIn 2s ease-in;
    animation-fill-mode: forwards;
  }

  @keyframes fullFadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1.0;
    }
  }

  .fade-out {
    -webkit-animation: fadeOut 2s ease-out;
    -moz-animation: fadeOut 2s ease-out;
    -o-animation: fadeOut 2s ease-out;
    animation: fadeOut 2s ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1.0;
    }

    100% {
      opacity: 0;
    }
  }

  .pop-out {
    -webkit-animation: popOut 0.5s linear;
    -moz-animation: popOut 0.5s linear;
    -o-animation: popOut 0.5s linear;
    animation: popOut 0.5s linear;
    animation-fill-mode: forwards;
  }

  @keyframes popOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes fadeToGray {
    0% {
      opacity: 1.0;
    }

    100% {
      opacity: 0.4;
    }
  }

  @keyframes floatCloudUpDwn {
    0% { transform: translateY( 0% ); }
    25% { transform: translateY( -5px ); }
    50% { transform: translateY( 5px ); }
    100% { transform: translateY( 0% ); }
  }

  @keyframes floatCloudDwnUp {
    0% { transform: translateY( 0% ); }
    25% { transform: translateY( 6px ); }
    50% { transform: translateY( -6px ); }
    100% { transform: translateY( 0% ); }
  }

  @keyframes taskBtnAppear {
    0% {
      transform: scale(0);
    }

    98% {
      transform: scale(1.1)
    }

    100% {
      transform: scale(1)
    }
  }

  @keyframes cloud1DropIn {
    0% {
      transform: translateY(-400px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes cloud2DropIn {
    0% {
      transform: translateY(-400px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes cloud3DropIn {
    0% {
      transform: translateY(-400px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes cloud4DropIn {
    0% {
      transform: translateY(-800px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes cloud5DropIn {
    0% {
      transform: translateY(-800px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes cloud6DropIn {
    0% {
      transform: translateY(-800px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes cloud7DropIn {
    0% {
      transform: translateY(-1200px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes cloud8DropIn {
    0% {
      transform: translateY(-1200px);
    }

    /*98% {
      transform: translateY(10%)
    }*/

    100% {
      transform: translateY(0%)
    }
  }

  @keyframes hover {
    0% {
      transform: translate(0px, 0px);
    }

    50% {
      transform: translate(0px, 5px);
    }

    100% {
      transform: translate(0px, 0px);
    }

  }

  @keyframes blink {
  0% { border: 6px solid; padding: 0px; border-color: #4A90E2; }
  50% { border: 6px solid; padding: 0px; border-color: #195DAC; }
  100% { border: 6px solid; padding: 0px; border-color: #4A90E2; }
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }


  /*
    Allow angular.js to be loaded in body, hiding cloaked elements until
    templates compile.  The !important is important given that there may be
    other selectors that are more specific or come later and might alter display.
   */
  [ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
  }

  .ng-hide {
    display: none !important;
  }

  /* .ng-hide-add {
    transition: 0s linear all;
  } */

  /* Alternate post said to deactivate more...
  .ng-hide,
  .ng-animate,
  .ng-hide-animate,
  .ng-hide-remove {
      display: none !important;
  }

  .ng-animate.no-animate {
      -webkit-transition: 0s none;
              transition: 0s none;
       -webkit-animation: 0s none;
               animation: 0s none;
  } */


/* Task complete animation */


.task_bar_task_name {
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 2px;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  line-height: 100%;
  -webkit-animation: taskBarNameAnimation 3s ease-out;
  -moz-animation: taskBarNameAnimation 3s ease-out;
  -o-animation: taskBarNameAnimation 3s ease-out;
  animation: taskBarNameAnimation 3s ease-out;
  animation-fill-mode: none;
}

.task_bar_task_name.long {
  -webkit-animation: taskBarLongNameAnimation 3s ease-out;
  -moz-animation: taskBarLongNameAnimation 3s ease-out;
  -o-animation: taskBarLongNameAnimation 3s ease-out;
  animation: taskBarLongNameAnimation 3s ease-out;
}

.task_bar_task_subtext {
  font-family: Helvetica;
  font-weight: bold;
  float: right;
  color: #F39061;
  letter-spacing: 2.94px;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  line-height: 100%;
  -webkit-animation: taskBarSubTextAnimation 3s ease-out;
  -moz-animation: taskBarSubTextAnimation 3s ease-out;
  -o-animation: taskBarSubTextAnimation 3s ease-out;
  animation: taskBarSubTextAnimation 3s ease-out;
  animation-fill-mode: none;
}

@keyframes taskBarIconAnimation {
  0% { transform: translate(-10vh, 33vh) scale(0,0); padding-right: 2vw; }
 40% { transform: translate(-10vh, 33vh) scale(0,0); padding-right: 2vw; }
 55% { transform: translate(-10vh, 33vh) scale(1.6,1.6); padding-right: 2vw; }
 58% { transform: translate(-10vh, 33vh) scale(1.4,1.4); padding-right: 2vw; }
 60% { transform: translate(-10vh, 33vh) scale(1.6,1.6); padding-right: 2vw; }
 80% { transform: translate(-10vh, 33vh) scale(1.6,1.6); padding-right: 2vw; }
 100% { transform: translate(0px, 0px) scale(1.0,1.0); }
}

.task_bar_task_icon {
  float: left;
  margin-top: 3%;
  -webkit-animation: taskBarIconAnimation 3s ease-out;
  -moz-animation: taskBarIconAnimation 3s ease-out;
  -o-animation: taskBarIconAnimation 3s ease-out;
  animation: taskBarIconAnimation 3s ease-out;
  animation-fill-mode: none;
}

/* Reports section */

.report-section-header {
  background-color: rgba(211, 211, 211, .4);
  color: black;
  padding: 1px;
}

.full-table-border {
  border: 1px solid #ddd;
  width: 75vw;
}

.full-width {
  width: 75vw;
}

.full-screen-width {
  width: 100%;
}

.top-table-border {
  width: 100%;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  border-top: 1px solid rgba(211, 211, 211, 0.8);
  border-bottom: 1px solid rgba(211, 211, 211, 0.8);
}

.top-table-border:last-of-type {
  border-right: none;
}

.top-table-priorities-section {
  width: 1vw;
  vertical-align: top;
}

.top-table-decoding-section {
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
}

.top-table-automaticity-section {
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
}

.top-table-screener-results-section {
  width: 1vw;
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
  padding-right: 5vw;
}

.pie-legend {
  padding-left: 2.6rem;
  white-space: nowrap;
}

.pie-img {
  display: none;
}

.diagnosticproduct-management-div-pie {
  height: 20vh;
  width: 12vw;
  margin: 10px;
}

.diagnosticproduct-management-pie-placeholder {
  display: table;
  text-align: center;
  margin: auto;
  padding: 10px;
  color: #808080;
}

.table-header-sortable {
  text-decoration: underline;
}

.table-section-header {
  background-color: #F7F7F7;
  color: black;
}

.table-section-header-alignment {
  text-align: center;
  border-right: 2px solid rgba(211, 211, 211, .8);
  font-size: 1.2rem;
  vertical-align: middle !important;
}

.table-section-header-alignment:last-of-type {
  border-right: none;
}

.detail-table-section-header-alignment {
  text-align: center;
  font-size: 1.2rem;
  vertical-align: middle !important;
  height: 6vh;
}

.detail-table-section-header {
  border-top: 1px solid #ddd;
  height: 10vh;
}

.diagnosticproduct-management-reports-table {
  height: 8vh;
  width: 100%;
}

.group-summary-text {
  margin-left: 10px;
  font-weight: bold;
  color: #808080;
}

.print-summary-button {
  float: right;
  /*width: 10%;*/
  width: 15vw;
  font-size: 1.5rem;
  background-color: #50AEA7;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
  margin-top: 7px;
  margin-right: 10px;
}

.print-summary-button:hover {
  color: white;
}

.print-student-detail-button {
  width: 40%;
  height: 80%;
  background-color: #50AEA7;
  border-radius: 4px;
  font-size: 1.25rem;
  color: white;
  border: none;
  /*padding: 1px 1px 1px 1px;*/
  padding: 2px 4px 2px 4px;
  /*margin-right: 45px;*/
  margin-top: 8px;
  float: right;
}

.print-student-detail-button:hover {
  color: white;
}

.return-to-summary-button {
  background-color: #4990E2;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
}

.return-to-summary-button:hover {
  color: white;
}

.print-selected-button {
  background-color: #50AEA7;
  border-radius: 4px;
  color: white;
  padding: 2px 4px 2px 4px;
  /*width: 175px;*/
  width: 15vw;
  font-size: 1.5rem;
  margin: 4% 2% 2% 2%;
}

.print-selected-button:hover {
  color: white;
}

.view-detail-button {
  background-color: #4990E2 ;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 2px 7px 2px 7px;
}

.view-detail-button:hover {
  color: white;
}

.prev-detail-button {
  background-color: #4990E2 ;
  text-align: center;
  vertical-align: middle;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
}

.prev-detail-button:hover {
  color: white;
}

.prev-next-button {
  background-color: #4990E2 ;
  text-align: center;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
}

.prev-next-button:hover {
  color: white;
}

.table-row-alignment {
  text-align: center;
}

.teacher-text {
  margin: 25px;
  font-size: 18px;
  text-align: left;
}

.instructional-priorities {
  width: 21vw;
  list-style-type: none;
  text-align: left;
  font-size: 1.4rem;
  padding-bottom: 10%;
  padding-left: 7%;
}

.dialog-background-reports {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  background-image: linear-gradient(-180deg, #A3D7DB 2%, #50AEA7 87%);
  opacity: 0.7;
  position: absolute;
}

.dialog-container-reports {
  max-height: 70%;
  max-width: 70%;
  height: 50%;
  width: 50%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 20%;
  left: 25%;
  background-image: linear-gradient(180deg, #A3D7DB 2%, #50AEA7 86%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 2px solid #4A4A4A;
}

.save-data-dialog-top-container-reports {
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  /*border-bottom: 2px solid black;*/
  position: absolute;
  overflow: hidden;
}

.save-data-dialog-bottom-container-reports {
  height: 25%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-around;
}

.save-data-dialog-text-reports {
  font-family: Helvetica;
  /*font-size: 30px;*/
  font-size: 1.5vw;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  position: relative;
  margin-left: 2vw;
  margin-top: 2vh;
  text-align: left;
}

.hidden {
  display: none;
}
/* Student detail reports */

.decoding-charts-section {
  background-color: white;
  padding: 0%;
  float: left;
  height: 45vh;
}

.generalization-charts-section {
  background-color: white;
  padding: 0%;
  float: left;
  height: 45vh;
}

.automaticity-charts-section {
  background-color: white;
  padding: 0%;
  float: left;
  height: 45vh;
}

.decoding-header-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.decoding-header-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}
.decoding-header-green {
  background-color:rgba(88,185,87,0.5); /* #58B957 */
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.decoding-border-red {
  width:48%;
  margin-right:1%;
  margin-left:1%;
  border: solid #DB524B;
}

.decoding-border-yellow {
  width:48%;
  margin-right:1%;
  margin-left:1%;
  border: solid #F2AE43;
}

.decoding-border-green {
  width:48%;
  margin-right:1%;
  margin-left:1%;
  border: solid #58B957;
}

.generalization-header {
  background-color: lightgray;
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.generalization-subhead {
  text-align: center;
  margin-top: 10%;
}

.automaticity-header-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */;
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.automaticity-header-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */;
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.automaticity-header-green {
  background-color: rgba(88,185,87,0.5); /* #58B957 */;
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.automaticity-border-red {
  width:23%;
  margin-right:1%;
  border: solid #DB524B;
}

.automaticity-border-yellow {
  width:23%;
  margin-right:1%;
  border: solid #F2AE43;
}

.automaticity-border-green {
  width:23%;
  margin-right:1%;
  border: solid #58B957;
}

.automaticity-subhead {
  text-align: center;
  margin-top: 8%;
}

.decodingCategories {
  font-size: 1vw;
  margin-right:1%;
}

.decodingSubheads {
  border: 1px solid black;
  overflow: hidden;
}

.decoding-subhead-consonants {
  background-color: rgba(88,185,87,0.5);
  border-radius: 2px;
  border: 2px solid #58B957;
  height: 1%;
  width: 42%;
  margin-left: 2.5vw;
  margin-top: -5px;
  float: left;
  position: relative;
}

.decoding-subhead-vowels {
  background-color: rgba(88,185,87,0.5);
  border-radius: 2px;
  border: 2px solid #58B957;
  height: 1%;
  width: 45%;
  margin-left: 10px;
  margin-top: -5px;
  float: left;
  position: relative;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.legend-score {
  padding-left: 0.5rem;
  vertical-align: middle;
}

.student-score {
  margin: -20px -7px 0px 0;
}

.chart-score {
  margin: -23px 0 0 50px;
}

.icon-img {
  display: none;
  float: left;
  padding-left: 11%;
  margin: 0 -130px 0 0;
}

.chart-img {
  display: none;
}

.legend-img {
  display: none;
  padding-left: 5px;
  padding-bottom: 5px;
  margin-top: -25px;
}

.detail-brackets-img {
  width: 100%;
  padding-left: 2vw;
  padding-right: 1.5vw;
  margin-top: -20px;
}

.instructional-priority-details {
  float: left;
  margin-left: 2%;
  width: 40vw;
  overflow: hidden;
}

.instructional-priority-links {
  float: left;
  margin-left: 2%;
  width: 40vw;
  overflow: hidden;
}

.consonant-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */
  border-color: rgba(219,82,75,0.5); /* #DB524B */
  color: #3D3D3D;
  text-align: center;
}

.consonant-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */
  border-color: rgba(242,174,67,0.5); /* #F2AE43 */
  color: #3D3D3D;
  text-align: center;
}
.consonant-green {
  background-color:rgba(88,185,87,0.5); /* #58B957 */
  border-color:rgba(88,185,87,0.5); /* #58B957 */
  color: #3D3D3D;
  text-align: center;
}

.vowel-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */
  border-color: rgba(219,82,75,0.5); /* #DB524B */
  color: #3D3D3D;
  text-align: center;
}

.vowel-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */
  border-color: rgba(242,174,67,0.5); /* #F2AE43 */
  color: #3D3D3D;
  text-align: center;
}
.vowel-green {
  background-color:rgba(88,185,87,0.5); /* #58B957 */
  border-color:rgba(88,185,87,0.5); /* #58B957 */
  color: #3D3D3D;
  text-align: center;
}

.enrollment-grade-dropdown
{
  height: 100%;
}

.enrollment-session-time-dropdown
{
  height: 100%;
}

.enrollment-radio-button
{
  border-style:  solid;
  border-width:  1px;
  border-color:  rgba(122, 119, 119, 0.5);
  border-radius: 5px;
  padding-left:  3px;
}

.assign-remove-diagnostic-button {
  height: 25px;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 4px;
  border: none;
  padding: 2px 4px 2px 4px;
}

.assign-diagnostic-sub-cell {
  height: 25px;
  display: table-cell;
  vertical-align: middle;
}

/*trial timer bar svg graphics*/
.timer-bar-empty{
  height: 30px;
  width: 85%;
  background-image: url(../assets/images/timerbar/timerBar_empty.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  margin: auto;
}


/*trial timer bar graphics*/
.timer-bar-container{
  width: 100%;
  margin: auto;
  margin-bottom: 1%;
  min-height: 7%;
}


.timer-bar-colored{
  position: relative;
  top: -78%;
  height: 30px;
  width: 83%;
  background-image: url(../assets/images/timerbar/timerBar_full.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-position: center;
  margin: auto;
}

.timer-counter-box{
  font-family: Helvetica;
  font-weight: bold;
  letter-spacing: 3.6px;
  pointer-events: auto;
  height: 100%;
  width: 7%;
  margin: auto;
  background: #B3D2A1;
  border: 1px solid #979797;
  border-radius: 10px;
  position: relative;
  top: -142%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctm-main-container {
  width: 90%;
  height: 63%;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-left: 5%; }

.ctm-response-container {
  width: 63%;
  height: 100%;
  margin: auto;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

.ctm-dictionary-container {
  width: 35%;
  height: 100%;
  margin: auto;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }

.ctm-parts-container {
  width: 95%;
  height: 25%;
  margin: auto;
  position: relative;
  top: 2%;
  margin-bottom: 3%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #4A4A4A;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
 }

.ctm-areas {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding-top: 10px ;
}

.ctm-prefix-suffix-width {
  position: relative ;
  width: 20%;
}

.ctm-root-word-width {
  position: relative;
  width: 40%;
}

.ctm-root-word-area {
  height: 50px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.ctm-base-root-text {
  font-size: 1rem;
  font-weight: bold;
  color: #4A4A4A;
  margin-top: 0;
  padding: 0;
}

.ctm-prefix-tile {
  height: 50px;
  margin: auto;
  border-radius: 10px;
  text-align: center;
  background-color: #FFF9AB;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
 }

 .ctm-suffix-tile {
  height: 50px;
  margin: auto;
  border-radius: 10px;
  text-align: center;
  background-color: rgba(238, 206, 245, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
 }

.ctm-prefix-text {
  font-size: 1rem;
  font-weight: bold;
  color: #A79B00;
  margin-top: 0;
  padding: 0;
}

.ctm-suffix-text {
  font-size: 1rem;
  font-weight: bold;
  color: #722f7e;
  margin-top: 0px;
  padding: 0;
}

.ctm-lower-container {
  width: 96%;
  height: 100%;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-left: 2%; }


.ctm-base-root-select-container {
  width: 40%;
  height: 56%;
  margin: auto;
  margin-top: 1vh;
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px solid #4A4A4A;
  border-radius: 10px;
 }

.ctm-prefixes-select-container {
  width: 25%;
  height: 56%;
  margin: auto;
  margin-top: 1vh;
  background-color: rgba(247, 223, 116, 0.4);
  border: 2px solid #4A4A4A;
  border-radius: 10px;
}

.ctm-suffixes-select-container {
  width: 25%;
  height: 56%;
  margin: auto;
  margin-top: 1vh;
  background-color: rgba(238, 206, 245, 0.3);
  border: 2px solid #4A4A4A;
  border-radius: 10px;
}

.ctm-prefix-select-container-header {
  font-size: 1.25rem;
  font-weight: bold;
  color: #4A4A4A;
  background-color: #f7df74;
  padding-top: 3px;
  border-bottom: 2px solid #4A4A4A;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 35px;
  text-align: center;
}

.ctm-base-root-select-container-header {
  font-size: 1.25rem;
  font-weight: bold;
  color: #4A4A4A;
  background-color: white;
  padding-top: 3px;
  border-bottom: 2px solid #4A4A4A;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 35px;
  text-align: center;
}

.ctm-suffix-select-container-header {
  font-size: 1.25rem;
  font-weight: bold;
  color: #4A4A4A;
  background-color: rgba(238, 206, 245, 1);
  padding-top: 3px;
  border-bottom: 2px solid #4A4A4A;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 35px;
  text-align: center;
}

.prefix-suffix-select-options-container {
  width: 100%;
  height: 20%;
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  position: relative;
 }

.base-root-select-options {
  color: #333 ;
  cursor: pointer ;
  width: 85%;
  margin: auto;
  background-color: white;
  border-width: 0;
  border-style: solid ;
  border-radius: 10px;
  text-align: center;
  padding-top: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
 }

.base-root-select-options:hover:not(:disabled),
.base-root-select-options-selected {
  border-width: 3px ;
}

.prefix-suffix-select-options-text {
  color: #333 ;
  width: 60%;
  margin: auto;
  background-color: white;
  border-width: 0;
  border-style: solid ;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  padding-top: 0px;
  position: relative;
  margin-left: 20px;
}

.prefix-suffix-select-options-text:hover:not(:disabled),
.prefix-suffix-select-options-text.selected {
  border-width: 3px ;
}

.ctm-space-around-column {
  height: 82%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.root-word-dictionary-container {
  width: 90%;
  height: 95%;
  margin: auto;
  margin-top: 1vh;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid #4A4A4A;
  border-radius: 10px;
}

.prefix-suffix-speaker-button {
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: none;
  border-radius: 15px;
  font-size: 1.5rem;
  z-index: 1;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 10px;
  top: -5px;
}

.prefix-suffix-target-speaker-button {
  width: 44px;
  height: 44px;
  background-color: transparent;
  border: none;
  border-radius: 15px;
  font-size: 1.5rem;
  z-index: 1;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: -20px;
  top: 15px;
}

.ctm-dictionary-speaker-button {
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: none;
  border-radius: 15px;
  font-size: 1.5rem;
  z-index: 1;
  background-image: url(../assets/images/audioBttnSupport.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-position: center;
  position: relative;
  top: 6px;
}

.prefix-suffix-info-button {
  width: 18px;
  height: 18px;
  background-color: rgba(211, 211, 211, .4);
  border: none;
  border-radius: 15px;
  font-size: 1.5rem;
  z-index: 1;
  background-image: url(../assets/images/buttons/infoBttn.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-position: center;
  margin-top: 12px;
  margin-right: 10px;
  position: relative;
}

.rts-sentence-type-info-button {
  position: absolute;
  top: 10%;
  right: 43.5%;
  cursor: pointer;
}

.ctm-dictionary-sections {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background-image: url(../assets/images/tasks/changethemeaning_dictionaryBckgnd.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-position: center;
}

.ctm-dictionary-sections.find {
  background-image: url(../assets/images/tasks/changethemeaning_dictionaryBckgnd-A.svg);
}

.ctm-dictionary-sections.change {
  background-image: url(../assets/images/tasks/changethemeaning_dictionaryBckgnd-B.svg);
}

.ctm-dictionary-top,
.ctm-dictionary-bottom {
  width: 100%;
  height: 45%;
  display: flex;
  /*justify-content: space-around;*/
  align-items: flex-start;
  flex-direction: row;
  top: 100px ;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  column-gap: 5px ;
}

.root-word-dictionary-top-left,
.ctm-dictionary-bottom-left {
  flex: 1 ;
  height: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding-left: 15px;
  padding-bottom: 20px;

}

.root-word-dictionary-top-right,
.ctm-dictionary-bottom-right {
  flex: 1 ;
  height: 100%;
  display: flex;
  justify-content: flex-start ;
  align-items: center;
  flex-direction: column;
}

.root-word-top-image-placeholder {
  background-color: white;
}

.root-word-bottom-image-placeholder {
  background-color: white;
}

.root-word-top-dictionary-word {
  font-weight: bold;
  color: #4A4A4A;
  padding: 0;
  min-height: 15%;
}

.root-word-top-dictionary-definition,
.root-word-bottom-dictionary-definition {
  line-height: 25px ;
}

.ctm-star-container {
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.ctm-star-submit {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 6px;
  background-size: contain;
  box-sizing: border-box;
  border: #81BE43;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 11px;
  background-image: url(../assets/images/buttons/submitBttnShape.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #81BE43;
  background-size: 80%; }

.ctm-star-submit-disabled {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 6px;
  background-size: contain;
  box-sizing: border-box;
  border: #81BE43;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
  border-radius: 11px;
  background-image: url(../assets/images/buttons/submitBttnShape.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #81BE43;
  background-size: 80%;
  opacity: 0.4; }

.semi-transparent {
  opacity: 0.5;
}

.base-root-select-options.fully-transparent {
  cursor: auto ;
}

.fully-transparent {
  opacity: 0;
}

.ctm-yellow-text {
  color: #A79B00;
}

.ctm-purple-text {
  color: #722F7E;
}

@keyframes blink-counter-box {
  50%{
    border-width: 5px;
  }
}

.timer-counter-box-blink{
  -webkit-animation: blink-counter-box 0.25s step-end 4 alternate;
  -moz-animation:    blink-counter-box 0.25s step-end 4 alternate;
  -o-animation:      blink-counter-box 0.25s step-end 4 alternate;
  animation:         blink-counter-box 0.25s step-end 4 alternate;
  border-color: green;
}

@keyframes floatPointsUp {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(0px, -5vw);
    opacity: 0;
  }
}

.float-up {
  -webkit-animation: floatPointsUp 0.5s linear 1.25s 1 normal forwards;
  -moz-animation:    floatPointsUp 0.5s linear 1.25s 1 normal forwards;
  -o-animation:      floatPointsUp 0.5s linear 1.25s 1 normal forwards;
  animation:         floatPointsUp 0.5s linear 1.25s 1 normal forwards;
}

#timer-points {
  margin: 0;
}

.float-incorrect-up {
  -webkit-animation: floatIncorrectUp 1s linear 1.25s 1 normal forwards;
  -moz-animation:    floatIncorrectUp 1s linear 1.25s 1 normal forwards;
  -o-animation:      floatIncorrectUp 1s linear 1.25s 1 normal forwards;
  animation:         floatIncorrectUp 1s linear 1.25s 1 normal forwards;
}



.float-incorrect-top-up {
  -webkit-animation: floatIncorrectTopUp 1s linear 1.25s 1 normal forwards;
  -moz-animation:    floatIncorrectTopUp 1s linear 1.25s 1 normal forwards;
  -o-animation:      floatIncorrectTopUp 1s linear 1.25s 1 normal forwards;
  animation:         floatIncorrectTopUp 1s linear 1.25s 1 normal forwards;
}


@media print {
  #header{ display:none;}
  #sidebar{ display:none;}
  #hideFromPrint{ display:none;}
  #printButton{ display:none;}
  #tableHeader{ border:none; width: 100%}
  #contentarea{ width:100%;}
  .full-table-border{ width: 100%;}
  .top-table-border{ width: 100%; height: 20pt;} /*summary*/
  .decoding-charts-section{ height: 250pt;}
  .generalization-charts-section{ height: 250pt;}
  .automaticity-charts-section{ height: 250pt;}
  .chart-bar{ margin-top: -175pt; padding: 10pt;}
  .chart-score { padding-bottom: 5pt;}
  .detail-brackets-img { margin-top: 0px;}
  .decoding-subhead-consonants { height: 18pt; margin-top: 10px;}
  .decoding-subhead-vowels { height: 18pt; margin-top: 10px;}
  .automaticity-subhead { margin-top: 20pt;}
  .generalization-subhead { margin-top: 20pt;}
  .instructional-priorities { font-size: 10pt !important;}
  .instructional-priority-details { margin-top: -10pt;}
  .instructional-priority-links { margin-top: -10pt;}
  p1{ color:lightgray !important;}
  .chart-pie{ margin-top: -175pt; display: none;}
  .pie-legend{ font-size: 10pt;}
  .icon-img{ display: block !important;}
  .chart-img{ display: block !important;}
  .legend-img{ display: block !important;}
  .pie-img{ display: block !important;}
  .diagnosticproduct-management-pie-placeholder{ visibility: hidden;}
  .diagnosticproduct-management-div-pie{ width: 80%;}
  .table-header-sortable{ text-decoration: none; }
}

/**
 * Because these styles are applied to the Capacitor app on iOS, we need to make sure any
 * hover classes that occur on buttons that can be pressed but do not change the route are
 * only on devices that support a physical hover (*not* mobile). This media query assures that
 * these styles are only used when the physical hover is possible
 */
@media (hover: hover) {
  .star-submit:hover {
    border: 6px solid rgb(85, 124, 42);
    padding: 0;
  }

  .ctm-star-submit:hover:not(:disabled) {
    border: 4px solid rgb(85, 124, 42);
    padding: 0;
  }

  .response-block:hover:not([disabled]),
  .silent-e:hover:not([disabled]),
  .response-block:hover + .silent-e {
    border: 6px solid;
    padding: 0;
  }

  .verify-response-correct:hover,
  .verify-response-incorrect:hover,
  .next-trial:hover,
  .rhyme-response-block:hover,
  .next-button:hover {
    border: 6px solid;
    padding: 0px;
    box-sizing: border-box;
  }

  .again-button:hover {
    border: 2px solid;
    padding: 0px;
    box-sizing: border-box;
  }
}

.pointer {
  cursor: pointer ;
}
